<template>
    <div id="root">
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
export default {
    name: "app",
};
</script>

<style src="./global.css"></style>
