export default {
    data() {
        return {
            mediaQuery: this.$mediaQuery,
        };
    },
    created() {
        try {
            window.addEventListener("resize", this.resizeHandler);
        } catch (e) {
            console.error("Cannot set up resize watcher - ", e);
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    methods: {
        resizeHandler() {
            this.mediaQuery = this.$mediaQuery.getMediaQueryObj();
        },
    },
};
