<template>
    <div class="loading-indicator-root">
        <div class="loading-indicator-wrapper">
            <div class="loading-indicator-inner-wrapper">
                <ProgressSpinner
                    class="loading-indicator"
                    strokeWidth="6"
                    animationDuration="2s"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ProgressSpinner from "primevue/progressspinner";

export default {
    components: {
        ProgressSpinner,
    },
};
</script>

<style src="./loadingIndicator.css"></style>
