<template>
    <div class="detail-root">
        <div v-if="manuals" class="detail-wrapper">
            <div class="detail-top"></div>
            <div class="detail-bottom">
                <div class="detail-left">
                    <!-- Current Manuals List goes here -->
                    <div class="detail-manual-list-wrapper">
                        <ScrollPanel
                            ref="detailScrollPanel"
                            class="detail-scroll-panel"
                            style="height: 81.25vh"
                        >
                            <div
                                v-for="(manual, index) in manuals"
                                :key="index"
                                :ref="`detailItemTile_${manual.id}`"
                                class="detail-item-tile"
                            >
                                <item-tile
                                    :data="manual"
                                    :isActive="
                                        currentManual && manual == currManual
                                    "
                                    @tileClick="onTileClick"
                                />
                            </div>
                        </ScrollPanel>
                    </div>
                </div>
                <div class="detail-right">
                    <div v-if="currManual" class="detail-display-wrapper">
                        <div class="detail-display-header">
                            <div class="detail-display-header-left">
                                <span class="detail-display-title">
                                    {{ currManual.title }}
                                </span>
                            </div>
                            <div class="detail-display-header-right">
                                <!--
                <span @click="onSaveClick" class="detail-display-control detail-display-control-save">
                  <span class="detail-display-icon-wrapper">
                    <i class="pi pi-save"></i>
                  </span>
                </span>
                -->
                                <span
                                    v-if="currManual.download_pdf_url"
                                    class="detail-display-control detail-display-control-print"
                                >
                                    <span
                                        @click="onPrintClick"
                                        class="detail-display-icon-wrapper p-button p-component p-button-icon-only p-button-rounded p-button-outlined"
                                    >
                                        <i class="pi pi-print"></i>
                                    </span>
                                </span>
                                <span
                                    v-if="currManual.download_pdf_url"
                                    class="detail-display-control detail-display-control-download"
                                >
                                    <a
                                        :download="
                                            currManual.title
                                                ? currManual.title
                                                : 'manual'
                                        "
                                        :href="currManual.download_pdf_url"
                                    >
                                        <span
                                            class="detail-display-icon-wrapper p-button p-component p-button-icon-only p-button-rounded p-button-outlined"
                                        >
                                            <i class="pi pi-download"></i>
                                        </span>
                                    </a>
                                </span>
                                <div
                                    id="closebtn"
                                    class="p-button p-button-sm p-component p-button-rounded p-button-secondary p-button-info"
                                    @click="onCloseClick"
                                >
                                    <i class="pi pi-times"></i>
                                    <span class="p-button-label">Close</span>
                                </div>
                            </div>

                            <div
                                v-if="currManualMetadata"
                                class="detail-display-header-bottom"
                            >
                                <div class="detail-metadata-inner-wrapper">
                                    <span
                                        v-for="(
                                            meta, index
                                        ) in currManualMetadata"
                                        :key="index"
                                        class="detail-metadata"
                                    >
                                        <span class="detail-metadata-name">{{
                                            meta.name
                                        }}</span>
                                        <span class="detail-metadata-value">{{
                                            meta.value
                                        }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="detail-display-viewer">
                            <!-- Display / PDF Viewer Goes here -->
                            <div
                                v-if="currManual && currManual.download_pdf_url"
                            >
                                <PDFViewer
                                    id="mv-pdfViewer"
                                    ref="pdfViewer"
                                    :key="viewerReloadKey"
                                    :fileUrl="currManual.download_pdf_url"
                                />
                            </div>
                            <div
                                v-else-if="currManual && currManual.url"
                                class="detail-display-no-manual"
                            >
                                <div class="detail-display-background"></div>
                                <div class="detail-display-overlay-wrapper">
                                    <div class="detail-display-overlay">
                                        <div
                                            class="detail-display-overlay-title"
                                        >
                                            {{ currManual.title }}
                                        </div>
                                        <div
                                            class="detail-display-overlay-text"
                                        >
                                            To view {{ currManual.title }} visit
                                            {{ currManualFormattedUrl }}. Click
                                            "Open PDF" below.
                                        </div>
                                        <div
                                            class="detail-display-overlay-button-wrapper"
                                        >
                                            <a
                                                class="detail-display-overlay-button-link"
                                                :href="currManual.url"
                                                target="_blank"
                                            >
                                                <div
                                                    class="detail-display-overlay-button"
                                                >
                                                    Open Manual
                                                    <i
                                                        class="detail-display-overlay-button-icon pi pi-external-link"
                                                    ></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ItemTile from "../../../../components/itemTile/ItemTile.vue";
import ScrollPanel from "primevue/scrollpanel";
import PDFViewer from "../../../../components/pdfViewer/index.vue";

export default {
    name: "detail",
    props: ["manuals", "currentManual"],
    components: {
        ItemTile,
        ScrollPanel,
        PDFViewer,
    },
    data() {
        return {
            currManual: this.currentManual || null,
            viewerReloadKey: 0,
        };
    },
    mounted() {
        window.scrollTo(0, 0);
        if (this.currManual) {
            let currEl = this.$refs[`detailItemTile_${this.currManual.id}`];
            if (currEl && currEl[0]) {
                currEl[0].parentNode.scrollTop = currEl[0].offsetTop;
            }
        }
    },
    computed: {
        currManualFormattedUrl() {
            if (this.currManual && this.currManual.url) {
                try {
                    const url = new URL(this.currManual.url);
                    return url.host;
                } catch (e) {
                    console.error(e);
                    return this.currManual.url;
                }
            } else return null;
        },
        currManualMetadata() {
            if (this.currManual) {
                const getValue = (value) => {
                    return Array.isArray(value) ? value[0] : value;
                };

                let meta = [];
                if (this.currManual.brand)
                    meta.push({
                        name: "Brand",
                        value: getValue(this.currManual.brand),
                    });
                if (this.currManual.category)
                    meta.push({
                        name: "Category",
                        value: getValue(this.currManual.category),
                    });
                if (this.currManual.sub_category)
                    meta.push({
                        name: "Subcategory",
                        value: getValue(this.currManual.sub_category),
                    });
                if (this.currManual.product)
                    meta.push({
                        name: "Product",
                        value: getValue(this.currManual.product),
                    });
                if (this.currManual.model)
                    meta.push({
                        name: "Model",
                        value: getValue(this.currManual.model),
                    });

                if (meta.length > 0) return meta;
            }
            return null;
        },
    },
    watch: {
        currentManual(nv) {
            this.currManual = nv;
        },
        currManual() {
            this.viewerReloadKey++;
        },
    },
    methods: {
        onCloseClick() {
            this.$emit("closeClick");
        },
        onTileClick(manual) {
            this.currManual = manual;
        },
        onSaveClick() {
            // Not yet implemented
            console.log("Save clicked");
        },
        onPrintClick() {
            if (this.$refs?.pdfViewer != undefined) {
                const name = this.currManual.title
                    ? this.currManual.title
                    : "manual";
                this.$refs.pdfViewer.printPDF(name);
            }
        },
        onDownloadClick() {
            // Do we want to track download clicks?
        },
    },
};
</script>

<style src="./detail.css"></style>
