<template>
    <div class="item-tile-root" :class="{ 'active-tile': isActive }">
        <div v-if="data" class="item-tile-wrapper" @click="emitClick">
            <div class="item-tile-file">PDF</div>
            <div v-if="data.title" class="item-tile-title">
                {{ data.title }}
            </div>
            <div v-if="formattedUrl" class="item-tile-host">
                {{ formattedUrl }}
            </div>
            <div v-if="metadata" class="item-tile-metadata-wrapper">
                <div class="item-tile-metadata-inner-wrapper">
                    <span
                        v-for="(meta, index) in metadata"
                        :key="index"
                        class="item-tile-metadata"
                    >
                        <span class="item-tile-metadata-name">{{
                            meta.name
                        }}</span>
                        <span class="item-tile-metadata-value">{{
                            meta.value
                        }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "item-tile",
    props: ["data", "isActive"],
    computed: {
        metadata() {
            if (this.data) {
                const getValue = (value) =>
                    Array.isArray(value) ? value[0] : value;

                const meta = [];
                if (this.data.brand)
                    meta.push({
                        name: "Brand",
                        value: getValue(this.data.brand),
                    });
                if (this.data.category)
                    meta.push({
                        name: "Category",
                        value: getValue(this.data.category),
                    });
                if (this.data.sub_category)
                    meta.push({
                        name: "Subcategory",
                        value: getValue(this.data.sub_category),
                    });
                if (this.data.product)
                    meta.push({
                        name: "Product",
                        value: getValue(this.data.product),
                    });
                if (this.data.model)
                    meta.push({
                        name: "Model",
                        value: getValue(this.data.model),
                    });

                if (meta.length > 0) return meta;
            }
            return null;
        },
        formattedUrl() {
            if (this.data && this.data.url) {
                try {
                    const url = new URL(this.data.url);
                    return url.host;
                } catch (e) {
                    console.error(e);
                    return this.data.url;
                }
            } else return null;
        },
    },
    methods: {
        emitClick() {
            this.$emit("tileClick", this.data);
        },
    },
};
</script>

<style>
.item-tile-file {
    opacity: 0.35;
    font-size: 10px;
    padding: 5px;
    border-radius: 3px;
    border: 1px solid;
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: #3f3f3f;
    color: white;
}

.item-tile-root {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
}

.item-tile-wrapper {
    border: solid 1px #e0e4e9;
    border-radius: 6px;
    padding: 10px;
    cursor: pointer;
    flex-grow: 1;
    position: relative;
    background-color: #f7f7f7;
}

.active-tile .item-tile-wrapper {
    background-color: #84919b;
    color: white;
    cursor: default;
}

.active-tile .item-tile-title {
    font-weight: bold;
    color: white;
}

.active-tile .item-tile-host {
    color: white;
    opacity: 0.5;
}

.active-tile .item-tile-file {
    background-color: white;
    color: #3d3d3d;
    border: white;
}

.item-tile-title {
    font-weight: normal;
    font-size: 18px;
    color: #1a0dab;
    line-height: 24px;
    letter-spacing: 0.5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 80%;
    margin-bottom: 5px;
}

.item-tile-host {
    font-size: 14px;
    color: #4285f4;
    padding-bottom: 4px;
    word-wrap: break-word;
    line-height: 20px;
}

.item-tile-metadata-wrapper {
    font-size: 0.7em;
    position: relative;
}

.item-tile-metadata-inner-wrapper {
}

.item-tile-metadata {
    padding-right: 10px;
    display: inline-block;
    width: 33.3%;
}

.item-tile-metadata-value {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
}

.item-tile-metadata-name {
    font-weight: bold;
    margin-right: 5px;
}
</style>
