/**
 * @licstart The following is the entire license notice for the
 * Javascript code in this page
 *
 * Copyright 2021 Mozilla Foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * @licend The above is the entire license notice for the
 * Javascript code in this page
 */
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true,
});
exports.PDFViewerApplication =
    exports.PDFPrintServiceFactory =
    exports.DefaultExternalServices =
        void 0;

var _ui_utils = require("pdfjs-dist/lib/web/ui_utils.js");

var _app_options = require("pdfjs-dist/lib/web/app_options.js");

var _pdf = require("pdfjs-dist/lib/pdf");

var _pdf_cursor_tools = require("pdfjs-dist/lib/web/pdf_cursor_tools.js");

var _pdf_rendering_queue = require("pdfjs-dist/lib/web/pdf_rendering_queue.js");

var _overlay_manager = require("pdfjs-dist/lib/web/overlay_manager.js");

var _password_prompt = require("pdfjs-dist/lib/web/password_prompt.js");

var _pdf_attachment_viewer = require("pdfjs-dist/lib/web/pdf_attachment_viewer.js");

var _pdf_document_properties = require("pdfjs-dist/lib/web/pdf_document_properties.js");

var _pdf_find_bar = require("pdfjs-dist/lib/web/pdf_find_bar.js");

var _pdf_find_controller = require("pdfjs-dist/lib/web/pdf_find_controller.js");

var _pdf_history = require("pdfjs-dist/lib/web/pdf_history.js");

var _pdf_layer_viewer = require("pdfjs-dist/lib/web/pdf_layer_viewer.js");

var _pdf_link_service = require("pdfjs-dist/lib/web/pdf_link_service.js");

var _pdf_outline_viewer = require("pdfjs-dist/lib/web/pdf_outline_viewer.js");

var _pdf_presentation_mode = require("pdfjs-dist/lib/web/pdf_presentation_mode.js");

var _pdf_scripting_manager = require("pdfjs-dist/lib/web/pdf_scripting_manager.js");

var _pdf_sidebar = require("pdfjs-dist/lib/web/pdf_sidebar.js");

var _pdf_sidebar_resizer = require("pdfjs-dist/lib/web/pdf_sidebar_resizer.js");

var _pdf_thumbnail_viewer = require("pdfjs-dist/lib/web/pdf_thumbnail_viewer.js");

var _pdf_viewer = require("pdfjs-dist/lib/web/pdf_viewer.js");

var _secondary_toolbar = require("pdfjs-dist/lib/web/secondary_toolbar.js");

var _toolbar = require("pdfjs-dist/lib/web/toolbar.js");

var _viewer_compatibility = require("pdfjs-dist/lib/web/viewer_compatibility.js");

var _view_history = require("pdfjs-dist/lib/web/view_history.js");

const DEFAULT_SCALE_DELTA = 1.1;
const DISABLE_AUTO_FETCH_LOADING_BAR_TIMEOUT = 5000;
const FORCE_PAGES_LOADED_TIMEOUT = 10000;
const WHEEL_ZOOM_DISABLED_TIMEOUT = 1000;
const ENABLE_PERMISSIONS_CLASS = "enablePermissions";
const ViewOnLoad = {
    UNKNOWN: -1,
    PREVIOUS: 0,
    INITIAL: 1,
};
const ViewerCssTheme = {
    AUTOMATIC: 0,
    LIGHT: 1,
    DARK: 2,
};
const KNOWN_VERSIONS = [
    "1.0",
    "1.1",
    "1.2",
    "1.3",
    "1.4",
    "1.5",
    "1.6",
    "1.7",
    "1.8",
    "1.9",
    "2.0",
    "2.1",
    "2.2",
    "2.3",
];
const KNOWN_GENERATORS = [
    "acrobat distiller",
    "acrobat pdfwriter",
    "adobe livecycle",
    "adobe pdf library",
    "adobe photoshop",
    "ghostscript",
    "tcpdf",
    "cairo",
    "dvipdfm",
    "dvips",
    "pdftex",
    "pdfkit",
    "itext",
    "prince",
    "quarkxpress",
    "mac os x",
    "microsoft",
    "openoffice",
    "oracle",
    "luradocument",
    "pdf-xchange",
    "antenna house",
    "aspose.cells",
    "fpdf",
];

class DefaultExternalServices {
    constructor() {
        throw new Error("Cannot initialize DefaultExternalServices.");
    }

    static updateFindControlState(data) {}

    static updateFindMatchesCount(data) {}

    static initPassiveLoading(callbacks) {}

    static async fallback(data) {}

    static reportTelemetry(data) {}

    static createDownloadManager(options) {
        throw new Error("Not implemented: createDownloadManager");
    }

    static createPreferences() {
        throw new Error("Not implemented: createPreferences");
    }

    static createL10n(options) {
        throw new Error("Not implemented: createL10n");
    }

    static createScripting(options) {
        throw new Error("Not implemented: createScripting");
    }

    static get supportsIntegratedFind() {
        return (0, _pdf.shadow)(this, "supportsIntegratedFind", false);
    }

    static get supportsDocumentFonts() {
        return (0, _pdf.shadow)(this, "supportsDocumentFonts", true);
    }

    static get supportedMouseWheelZoomModifierKeys() {
        return (0, _pdf.shadow)(this, "supportedMouseWheelZoomModifierKeys", {
            ctrlKey: true,
            metaKey: true,
        });
    }

    static get isInAutomation() {
        return (0, _pdf.shadow)(this, "isInAutomation", false);
    }
}

exports.DefaultExternalServices = DefaultExternalServices;
const PDFViewerApplication = {
    initialBookmark: document.location.hash.substring(1),
    _initializedCapability: (0, _pdf.createPromiseCapability)(),
    fellback: false,
    appConfig: null,
    pdfDocument: null,
    pdfLoadingTask: null,
    printService: null,
    pdfViewer: null,
    pdfThumbnailViewer: null,
    pdfRenderingQueue: null,
    pdfPresentationMode: null,
    pdfDocumentProperties: null,
    pdfLinkService: null,
    pdfHistory: null,
    pdfSidebar: null,
    pdfSidebarResizer: null,
    pdfOutlineViewer: null,
    pdfAttachmentViewer: null,
    pdfLayerViewer: null,
    pdfCursorTools: null,
    pdfScriptingManager: null,
    store: null,
    downloadManager: null,
    overlayManager: null,
    preferences: null,
    toolbar: null,
    secondaryToolbar: null,
    eventBus: null,
    l10n: null,
    isInitialViewSet: false,
    downloadComplete: false,
    isViewerEmbedded: window.parent !== window,
    url: "",
    baseUrl: "",
    externalServices: DefaultExternalServices,
    _boundEvents: Object.create(null),
    documentInfo: null,
    metadata: null,
    _contentDispositionFilename: null,
    _contentLength: null,
    triggerDelayedFallback: null,
    _saveInProgress: false,
    _wheelUnusedTicks: 0,
    _idleCallbacks: new Set(),

    async initialize(appConfig) {
        this.initialBookmark = document.location.hash.substring(1);
        //this.preferences = DefaultExternalServices.createPreferences();
        this.appConfig = appConfig;
        await this._readPreferences();
        await this._parseHashParameters();

        this._forceCssTheme();

        await this._initializeL10n();

        if (
            this.isViewerEmbedded &&
            _app_options.AppOptions.get("externalLinkTarget") ===
                _pdf.LinkTarget.NONE
        ) {
            _app_options.AppOptions.set(
                "externalLinkTarget",
                _pdf.LinkTarget.TOP
            );
        }

        await this._initializeViewerComponents();
        this.bindEvents();
        this.bindWindowEvents();
        const appContainer = appConfig.appContainer || document.documentElement;
        this.l10n.translate(appContainer).then(() => {
            this.eventBus.dispatch("localized", {
                source: this,
            });
        });

        this._initializedCapability.resolve();
    },

    async _readPreferences() {
        if (_app_options.AppOptions.get("disablePreferences")) {
            return;
        }

        try {
            _app_options.AppOptions.setAll(await this.preferences.getAll());
        } catch (reason) {
            console.error(`_readPreferences: "${reason?.message}".`);
        }
    },

    async _parseHashParameters() {
        if (!_app_options.AppOptions.get("pdfBugEnabled")) {
            return undefined;
        }

        const hash = document.location.hash.substring(1);

        if (!hash) {
            return undefined;
        }

        const hashParams = (0, _ui_utils.parseQueryString)(hash),
            waitOn = [];

        if (
            "disableworker" in hashParams &&
            hashParams.disableworker === "true"
        ) {
            waitOn.push(loadFakeWorker());
        }

        if ("disablerange" in hashParams) {
            _app_options.AppOptions.set(
                "disableRange",
                hashParams.disablerange === "true"
            );
        }

        if ("disablestream" in hashParams) {
            _app_options.AppOptions.set(
                "disableStream",
                hashParams.disablestream === "true"
            );
        }

        if ("disableautofetch" in hashParams) {
            _app_options.AppOptions.set(
                "disableAutoFetch",
                hashParams.disableautofetch === "true"
            );
        }

        if ("disablefontface" in hashParams) {
            _app_options.AppOptions.set(
                "disableFontFace",
                hashParams.disablefontface === "true"
            );
        }

        if ("disablehistory" in hashParams) {
            _app_options.AppOptions.set(
                "disableHistory",
                hashParams.disablehistory === "true"
            );
        }

        if ("webgl" in hashParams) {
            _app_options.AppOptions.set(
                "enableWebGL",
                hashParams.webgl === "true"
            );
        }

        if ("verbosity" in hashParams) {
            _app_options.AppOptions.set("verbosity", hashParams.verbosity | 0);
        }

        if ("textlayer" in hashParams) {
            switch (hashParams.textlayer) {
                case "off":
                    _app_options.AppOptions.set(
                        "textLayerMode",
                        _ui_utils.TextLayerMode.DISABLE
                    );

                    break;

                case "visible":
                case "shadow":
                case "hover":
                    const viewer = this.appConfig.viewerContainer;
                    viewer.classList.add("textLayer-" + hashParams.textlayer);
                    break;
            }
        }

        if ("pdfbug" in hashParams) {
            _app_options.AppOptions.set("pdfBug", true);

            _app_options.AppOptions.set("fontExtraProperties", true);

            const enabled = hashParams.pdfbug.split(",");
            waitOn.push(loadAndEnablePDFBug(enabled));
        }

        if ("locale" in hashParams) {
            _app_options.AppOptions.set("locale", hashParams.locale);
        }

        if (waitOn.length === 0) {
            return undefined;
        }

        return Promise.all(waitOn).catch((reason) => {
            console.error(`_parseHashParameters: "${reason.message}".`);
        });
    },

    async _initializeL10n() {
        this.l10n = this.externalServices.createL10n({
            locale: _app_options.AppOptions.get("locale"),
        });
        const dir = await this.l10n.getDirection();
        document.getElementsByTagName("html")[0].dir = dir;
    },

    _forceCssTheme() {
        const cssTheme = _app_options.AppOptions.get("viewerCssTheme");

        if (
            cssTheme === ViewerCssTheme.AUTOMATIC ||
            !Object.values(ViewerCssTheme).includes(cssTheme)
        ) {
            return;
        }

        try {
            const styleSheet = document.styleSheets[0];
            const cssRules = styleSheet?.cssRules || [];

            for (let i = 0, ii = cssRules.length; i < ii; i++) {
                const rule = cssRules[i];

                if (
                    rule instanceof CSSMediaRule &&
                    rule.media?.[0] === "(prefers-color-scheme: dark)"
                ) {
                    if (cssTheme === ViewerCssTheme.LIGHT) {
                        styleSheet.deleteRule(i);
                        return;
                    }

                    const darkRules =
                        /^@media \(prefers-color-scheme: dark\) {\n\s*([\w\s-.,:;/\\{}()]+)\n}$/.exec(
                            rule.cssText
                        );

                    if (darkRules?.[1]) {
                        styleSheet.deleteRule(i);
                        styleSheet.insertRule(darkRules[1], i);
                    }

                    return;
                }
            }
        } catch (reason) {
            console.error(`_forceCssTheme: "${reason?.message}".`);
        }
    },

    async _initializeViewerComponents() {
        const appConfig = this.appConfig;
        const eventBus =
            appConfig.eventBus ||
            new _ui_utils.EventBus({
                isInAutomation: this.externalServices.isInAutomation,
            });
        this.eventBus = eventBus;
        this.overlayManager = new _overlay_manager.OverlayManager();
        const pdfRenderingQueue = new _pdf_rendering_queue.PDFRenderingQueue();
        pdfRenderingQueue.onIdle = this._cleanup.bind(this);
        this.pdfRenderingQueue = pdfRenderingQueue;
        const pdfLinkService = new _pdf_link_service.PDFLinkService({
            eventBus,
            externalLinkTarget:
                _app_options.AppOptions.get("externalLinkTarget"),
            externalLinkRel: _app_options.AppOptions.get("externalLinkRel"),
            ignoreDestinationZoom: _app_options.AppOptions.get(
                "ignoreDestinationZoom"
            ),
        });
        this.pdfLinkService = pdfLinkService;
        const downloadManager = this.externalServices.createDownloadManager();
        this.downloadManager = downloadManager;
        const findController = new _pdf_find_controller.PDFFindController({
            linkService: pdfLinkService,
            eventBus,
        });
        this.findController = findController;
        const pdfScriptingManager =
            new _pdf_scripting_manager.PDFScriptingManager({
                eventBus,
                sandboxBundleSrc:
                    _app_options.AppOptions.get("sandboxBundleSrc"),
                scriptingFactory: this.externalServices,
                docPropertiesLookup: this._scriptingDocProperties.bind(this),
            });
        this.pdfScriptingManager = pdfScriptingManager;
        const container = appConfig.mainContainer;
        const viewer = appConfig.viewerContainer;
        this.pdfViewer = new _pdf_viewer.PDFViewer({
            container,
            viewer,
            eventBus,
            renderingQueue: pdfRenderingQueue,
            linkService: pdfLinkService,
            downloadManager,
            findController,
            scriptingManager: pdfScriptingManager,
            renderer: _app_options.AppOptions.get("renderer"),
            enableWebGL: _app_options.AppOptions.get("enableWebGL"),
            l10n: this.l10n,
            textLayerMode: _app_options.AppOptions.get("textLayerMode"),
            imageResourcesPath:
                _app_options.AppOptions.get("imageResourcesPath"),
            renderInteractiveForms: _app_options.AppOptions.get(
                "renderInteractiveForms"
            ),
            enablePrintAutoRotate: _app_options.AppOptions.get(
                "enablePrintAutoRotate"
            ),
            useOnlyCssZoom: _app_options.AppOptions.get("useOnlyCssZoom"),
            maxCanvasPixels: _app_options.AppOptions.get("maxCanvasPixels"),
            enableScripting: _app_options.AppOptions.get("enableScripting"),
        });
        pdfRenderingQueue.setViewer(this.pdfViewer);
        pdfLinkService.setViewer(this.pdfViewer);
        pdfScriptingManager.setViewer(this.pdfViewer);
        this.pdfThumbnailViewer = new _pdf_thumbnail_viewer.PDFThumbnailViewer({
            container: appConfig.sidebar.thumbnailView,
            eventBus,
            renderingQueue: pdfRenderingQueue,
            linkService: pdfLinkService,
            l10n: this.l10n,
        });
        pdfRenderingQueue.setThumbnailViewer(this.pdfThumbnailViewer);
        this.pdfHistory = new _pdf_history.PDFHistory({
            linkService: pdfLinkService,
            eventBus,
        });
        pdfLinkService.setHistory(this.pdfHistory);

        if (!this.supportsIntegratedFind) {
            this.findBar = new _pdf_find_bar.PDFFindBar(
                appConfig.findBar,
                eventBus,
                this.l10n
            );
        }

        this.pdfDocumentProperties =
            new _pdf_document_properties.PDFDocumentProperties(
                appConfig.documentProperties,
                this.overlayManager,
                eventBus,
                this.l10n
            );
        this.pdfCursorTools = new _pdf_cursor_tools.PDFCursorTools({
            container,
            eventBus,
            cursorToolOnLoad: _app_options.AppOptions.get("cursorToolOnLoad"),
        });
        this.toolbar = new _toolbar.Toolbar(
            appConfig.toolbar,
            eventBus,
            this.l10n
        );
        this.secondaryToolbar = new _secondary_toolbar.SecondaryToolbar(
            appConfig.secondaryToolbar,
            container,
            eventBus
        );

        if (this.supportsFullscreen) {
            this.pdfPresentationMode =
                new _pdf_presentation_mode.PDFPresentationMode({
                    container,
                    pdfViewer: this.pdfViewer,
                    eventBus,
                });
        }

        this.passwordPrompt = new _password_prompt.PasswordPrompt(
            appConfig.passwordOverlay,
            this.overlayManager,
            this.l10n,
            this.isViewerEmbedded
        );
        this.pdfOutlineViewer = new _pdf_outline_viewer.PDFOutlineViewer({
            container: appConfig.sidebar.outlineView,
            eventBus,
            linkService: pdfLinkService,
        });
        this.pdfAttachmentViewer =
            new _pdf_attachment_viewer.PDFAttachmentViewer({
                container: appConfig.sidebar.attachmentsView,
                eventBus,
                downloadManager,
            });
        this.pdfLayerViewer = new _pdf_layer_viewer.PDFLayerViewer({
            container: appConfig.sidebar.layersView,
            eventBus,
            l10n: this.l10n,
        });
        this.pdfSidebar = new _pdf_sidebar.PDFSidebar({
            elements: appConfig.sidebar,
            pdfViewer: this.pdfViewer,
            pdfThumbnailViewer: this.pdfThumbnailViewer,
            eventBus,
            l10n: this.l10n,
        });
        this.pdfSidebar.onToggled = this.forceRendering.bind(this);
        this.pdfSidebarResizer = new _pdf_sidebar_resizer.PDFSidebarResizer(
            appConfig.sidebarResizer,
            eventBus,
            this.l10n
        );
    },

    run(config) {
        this.initialize(config).then(webViewerInitialized);
    },

    get initialized() {
        return this._initializedCapability.settled;
    },

    get initializedPromise() {
        return this._initializedCapability.promise;
    },

    zoomIn(ticks) {
        if (this.pdfViewer.isInPresentationMode) {
            return;
        }

        let newScale = this.pdfViewer.currentScale;

        do {
            newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
            newScale = Math.ceil(newScale * 10) / 10;
            newScale = Math.min(_ui_utils.MAX_SCALE, newScale);
        } while (--ticks > 0 && newScale < _ui_utils.MAX_SCALE);

        this.pdfViewer.currentScaleValue = newScale;
    },

    zoomOut(ticks) {
        if (this.pdfViewer.isInPresentationMode) {
            return;
        }

        let newScale = this.pdfViewer.currentScale;

        do {
            newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
            newScale = Math.floor(newScale * 10) / 10;
            newScale = Math.max(_ui_utils.MIN_SCALE, newScale);
        } while (--ticks > 0 && newScale > _ui_utils.MIN_SCALE);

        this.pdfViewer.currentScaleValue = newScale;
    },

    zoomReset() {
        if (this.pdfViewer.isInPresentationMode) {
            return;
        }

        this.pdfViewer.currentScaleValue = _ui_utils.DEFAULT_SCALE_VALUE;
    },

    get pagesCount() {
        return this.pdfDocument ? this.pdfDocument.numPages : 0;
    },

    get page() {
        return this.pdfViewer.currentPageNumber;
    },

    set page(val) {
        this.pdfViewer.currentPageNumber = val;
    },

    get supportsPrinting() {
        return PDFPrintServiceFactory.instance.supportsPrinting;
    },

    get supportsFullscreen() {
        const doc = document.documentElement;
        let support = !!(
            doc.requestFullscreen ||
            doc.mozRequestFullScreen ||
            doc.webkitRequestFullScreen
        );

        if (
            document.fullscreenEnabled === false ||
            document.mozFullScreenEnabled === false ||
            document.webkitFullscreenEnabled === false
        ) {
            support = false;
        }

        return (0, _pdf.shadow)(this, "supportsFullscreen", support);
    },

    get supportsIntegratedFind() {
        return this.externalServices.supportsIntegratedFind;
    },

    get supportsDocumentFonts() {
        return this.externalServices.supportsDocumentFonts;
    },

    get loadingBar() {
        const bar = new _ui_utils.ProgressBar("#loadingBar");
        return (0, _pdf.shadow)(this, "loadingBar", bar);
    },

    get supportedMouseWheelZoomModifierKeys() {
        return this.externalServices.supportedMouseWheelZoomModifierKeys;
    },

    initPassiveLoading() {
        throw new Error("Not implemented: initPassiveLoading");
    },

    setTitleUsingUrl(url = "") {
        this.url = url;
        this.baseUrl = url.split("#")[0];
        let title = (0, _pdf.getPdfFilenameFromUrl)(url, "");

        if (!title) {
            try {
                title =
                    decodeURIComponent((0, _pdf.getFilenameFromUrl)(url)) ||
                    url;
            } catch (ex) {
                title = url;
            }
        }

        this.setTitle(title);
    },

    setTitle(title) {
        if (this.isViewerEmbedded) {
            return;
        }

        document.title = title;
    },

    get _docFilename() {
        return (
            this._contentDispositionFilename ||
            (0, _pdf.getPdfFilenameFromUrl)(this.url)
        );
    },

    _cancelIdleCallbacks() {
        if (!this._idleCallbacks.size) {
            return;
        }

        for (const callback of this._idleCallbacks) {
            window.cancelIdleCallback(callback);
        }

        this._idleCallbacks.clear();
    },

    async close() {
        this._unblockDocumentLoadEvent();

        const { container } = this.appConfig.errorWrapper;
        container.hidden = true;

        if (!this.pdfLoadingTask) {
            return;
        }

        if (
            this.pdfDocument?.annotationStorage.size > 0 &&
            this._annotationStorageModified
        ) {
            try {
                await this.save({
                    sourceEventType: "save",
                });
            } catch (reason) {}
        }

        const promises = [];
        promises.push(this.pdfLoadingTask.destroy());
        this.pdfLoadingTask = null;

        if (this.pdfDocument) {
            this.pdfDocument = null;
            this.pdfThumbnailViewer.setDocument(null);
            this.pdfViewer.setDocument(null);
            this.pdfLinkService.setDocument(null);
            this.pdfDocumentProperties.setDocument(null);
        }

        webViewerResetPermissions();
        this.store = null;
        this.isInitialViewSet = false;
        this.downloadComplete = false;
        this.url = "";
        this.baseUrl = "";
        this.documentInfo = null;
        this.metadata = null;
        this._contentDispositionFilename = null;
        this._contentLength = null;
        this.triggerDelayedFallback = null;
        this._saveInProgress = false;

        this._cancelIdleCallbacks();

        promises.push(this.pdfScriptingManager.destroyPromise);
        this.pdfSidebar.reset();
        this.pdfOutlineViewer.reset();
        this.pdfAttachmentViewer.reset();
        this.pdfLayerViewer.reset();

        if (this.pdfHistory) {
            this.pdfHistory.reset();
        }

        if (this.findBar) {
            this.findBar.reset();
        }

        this.toolbar.reset();
        this.secondaryToolbar.reset();

        if (typeof PDFBug !== "undefined") {
            PDFBug.cleanup();
        }

        await Promise.all(promises);
    },

    async open(file, args) {
        if (this.pdfLoadingTask) {
            await this.close();
        }

        const workerParameters = _app_options.AppOptions.getAll(
            _app_options.OptionKind.WORKER
        );

        for (const key in workerParameters) {
            _pdf.GlobalWorkerOptions[key] = workerParameters[key];
        }

        const parameters = Object.create(null);

        if (typeof file === "string") {
            this.setTitleUsingUrl(file);
            parameters.url = file;
        } else if (file && "byteLength" in file) {
            parameters.data = file;
        } else if (file.url && file.originalUrl) {
            this.setTitleUsingUrl(file.originalUrl);
            parameters.url = file.url;
        }

        const apiParameters = _app_options.AppOptions.getAll(
            _app_options.OptionKind.API
        );

        for (const key in apiParameters) {
            let value = apiParameters[key];

            if (key === "docBaseUrl" && !value) {
            }

            parameters[key] = value;
        }

        if (args) {
            for (const key in args) {
                parameters[key] = args[key];
            }
        }

        const loadingTask = (0, _pdf.getDocument)(parameters);
        this.pdfLoadingTask = loadingTask;

        loadingTask.onPassword = (updateCallback, reason) => {
            this.pdfLinkService.externalLinkEnabled = false;
            this.passwordPrompt.setUpdateCallback(updateCallback, reason);
            this.passwordPrompt.open();
        };

        loadingTask.onProgress = ({ loaded, total }) => {
            this.progress(loaded / total);
        };

        loadingTask.onUnsupportedFeature = this.fallback.bind(this);
        return loadingTask.promise.then(
            (pdfDocument) => {
                this.load(pdfDocument);
            },
            (exception) => {
                if (loadingTask !== this.pdfLoadingTask) {
                    return undefined;
                }

                let key = "loading_error";

                if (exception instanceof _pdf.InvalidPDFException) {
                    key = "invalid_file_error";
                } else if (exception instanceof _pdf.MissingPDFException) {
                    key = "missing_file_error";
                } else if (
                    exception instanceof _pdf.UnexpectedResponseException
                ) {
                    key = "unexpected_response_error";
                }

                return this.l10n.get(key).then((msg) => {
                    this._documentError(msg, {
                        message: exception?.message,
                    });

                    throw exception;
                });
            }
        );
    },

    _ensureDownloadComplete() {
        if (this.pdfDocument && this.downloadComplete) {
            return;
        }

        throw new Error("PDF document not downloaded.");
    },

    async download({ sourceEventType = "download" } = {}) {
        const url = this.baseUrl,
            filename = this._docFilename;

        try {
            this._ensureDownloadComplete();

            const data = await this.pdfDocument.getData();
            const blob = new Blob([data], {
                type: "application/pdf",
            });
            await this.downloadManager.download(
                blob,
                url,
                filename,
                sourceEventType
            );
        } catch (reason) {
            await this.downloadManager.downloadUrl(url, filename);
        }
    },

    async save({ sourceEventType = "download" } = {}) {
        if (this._saveInProgress) {
            return;
        }

        this._saveInProgress = true;
        await this.pdfScriptingManager.dispatchWillSave();
        const url = this.baseUrl,
            filename = this._docFilename;

        try {
            this._ensureDownloadComplete();

            const data = await this.pdfDocument.saveDocument(
                this.pdfDocument.annotationStorage
            );
            const blob = new Blob([data], {
                type: "application/pdf",
            });
            await this.downloadManager.download(
                blob,
                url,
                filename,
                sourceEventType
            );
        } catch (reason) {
            await this.download({
                sourceEventType,
            });
        } finally {
            await this.pdfScriptingManager.dispatchDidSave();
            this._saveInProgress = false;
        }
    },

    downloadOrSave(options) {
        if (this.pdfDocument?.annotationStorage.size > 0) {
            this.save(options);
        } else {
            this.download(options);
        }
    },

    _delayedFallback(featureId) {
        this.externalServices.reportTelemetry({
            type: "unsupportedFeature",
            featureId,
        });

        if (!this.triggerDelayedFallback) {
            this.triggerDelayedFallback = () => {
                this.fallback(featureId);
                this.triggerDelayedFallback = null;
            };
        }
    },

    fallback(featureId) {
        this.externalServices.reportTelemetry({
            type: "unsupportedFeature",
            featureId,
        });

        switch (featureId) {
            case _pdf.UNSUPPORTED_FEATURES.errorFontLoadNative:
            case _pdf.UNSUPPORTED_FEATURES.errorFontMissing:
                return;
        }

        if (this.fellback) {
            return;
        }

        this.fellback = true;
        this.externalServices
            .fallback({
                featureId,
                url: this.baseUrl,
            })
            .then((download) => {
                if (!download) {
                    return;
                }

                this.download({
                    sourceEventType: "download",
                });
            });
    },

    _documentError(message, moreInfo = null) {
        this._unblockDocumentLoadEvent();

        this._otherError(message, moreInfo);
    },

    _otherError(message, moreInfo = null) {
        const moreInfoText = [
            this.l10n.get("error_version_info", {
                version: _pdf.version || "?",
                build: _pdf.build || "?",
            }),
        ];

        if (moreInfo) {
            moreInfoText.push(
                this.l10n.get("error_message", {
                    message: moreInfo.message,
                })
            );

            if (moreInfo.stack) {
                moreInfoText.push(
                    this.l10n.get("error_stack", {
                        stack: moreInfo.stack,
                    })
                );
            } else {
                if (moreInfo.filename) {
                    moreInfoText.push(
                        this.l10n.get("error_file", {
                            file: moreInfo.filename,
                        })
                    );
                }

                if (moreInfo.lineNumber) {
                    moreInfoText.push(
                        this.l10n.get("error_line", {
                            line: moreInfo.lineNumber,
                        })
                    );
                }
            }
        }

        const errorWrapperConfig = this.appConfig.errorWrapper;
        const errorWrapper = errorWrapperConfig.container;
        errorWrapper.hidden = false;
        const errorMessage = errorWrapperConfig.errorMessage;
        errorMessage.textContent = message;
        const closeButton = errorWrapperConfig.closeButton;

        closeButton.onclick = function () {
            errorWrapper.hidden = true;
        };

        const errorMoreInfo = errorWrapperConfig.errorMoreInfo;
        const moreInfoButton = errorWrapperConfig.moreInfoButton;
        const lessInfoButton = errorWrapperConfig.lessInfoButton;

        moreInfoButton.onclick = function () {
            errorMoreInfo.hidden = false;
            moreInfoButton.hidden = true;
            lessInfoButton.hidden = false;
            errorMoreInfo.style.height = errorMoreInfo.scrollHeight + "px";
        };

        lessInfoButton.onclick = function () {
            errorMoreInfo.hidden = true;
            moreInfoButton.hidden = false;
            lessInfoButton.hidden = true;
        };

        moreInfoButton.oncontextmenu = _ui_utils.noContextMenuHandler;
        lessInfoButton.oncontextmenu = _ui_utils.noContextMenuHandler;
        closeButton.oncontextmenu = _ui_utils.noContextMenuHandler;
        moreInfoButton.hidden = false;
        lessInfoButton.hidden = true;
        Promise.all(moreInfoText).then((parts) => {
            errorMoreInfo.value = parts.join("\n");
        });
    },

    progress(level) {
        if (this.downloadComplete) {
            return;
        }

        const percent = Math.round(level * 100);

        if (percent > this.loadingBar.percent || isNaN(percent)) {
            this.loadingBar.percent = percent;
            const disableAutoFetch = this.pdfDocument
                ? this.pdfDocument.loadingParams.disableAutoFetch
                : _app_options.AppOptions.get("disableAutoFetch");

            if (disableAutoFetch && percent) {
                if (this.disableAutoFetchLoadingBarTimeout) {
                    clearTimeout(this.disableAutoFetchLoadingBarTimeout);
                    this.disableAutoFetchLoadingBarTimeout = null;
                }

                this.loadingBar.show();
                this.disableAutoFetchLoadingBarTimeout = setTimeout(() => {
                    this.loadingBar.hide();
                    this.disableAutoFetchLoadingBarTimeout = null;
                }, DISABLE_AUTO_FETCH_LOADING_BAR_TIMEOUT);
            }
        }
    },

    load(pdfDocument) {
        this.pdfDocument = pdfDocument;
        pdfDocument.getDownloadInfo().then(({ length }) => {
            this._contentLength = length;
            this.downloadComplete = true;
            this.loadingBar.hide();
            firstPagePromise.then(() => {
                this.eventBus.dispatch("documentloaded", {
                    source: this,
                });
            });
        });
        const pageLayoutPromise = pdfDocument
            .getPageLayout()
            .catch(function () {});
        const pageModePromise = pdfDocument.getPageMode().catch(function () {});
        const openActionPromise = pdfDocument
            .getOpenAction()
            .catch(function () {});
        this.toolbar.setPagesCount(pdfDocument.numPages, false);
        this.secondaryToolbar.setPagesCount(pdfDocument.numPages);
        let baseDocumentUrl;
        baseDocumentUrl = null;
        this.pdfLinkService.setDocument(pdfDocument, baseDocumentUrl);
        this.pdfDocumentProperties.setDocument(pdfDocument, this.url);
        const pdfViewer = this.pdfViewer;
        pdfViewer.setDocument(pdfDocument);
        const { firstPagePromise, onePageRendered, pagesPromise } = pdfViewer;
        const pdfThumbnailViewer = this.pdfThumbnailViewer;
        pdfThumbnailViewer.setDocument(pdfDocument);
        const storedPromise = (this.store = new _view_history.ViewHistory(
            pdfDocument.fingerprint
        ))
            .getMultiple({
                page: null,
                zoom: _ui_utils.DEFAULT_SCALE_VALUE,
                scrollLeft: "0",
                scrollTop: "0",
                rotation: null,
                sidebarView: _ui_utils.SidebarView.UNKNOWN,
                scrollMode: _ui_utils.ScrollMode.UNKNOWN,
                spreadMode: _ui_utils.SpreadMode.UNKNOWN,
            })
            .catch(() => {
                return Object.create(null);
            });
        firstPagePromise.then((pdfPage) => {
            this.loadingBar.setWidth(this.appConfig.viewerContainer);

            this._initializeAnnotationStorageCallbacks(pdfDocument);

            Promise.all([
                _ui_utils.animationStarted,
                storedPromise,
                pageLayoutPromise,
                pageModePromise,
                openActionPromise,
            ])
                .then(
                    async ([
                        timeStamp,
                        stored,
                        pageLayout,
                        pageMode,
                        openAction,
                    ]) => {
                        const viewOnLoad =
                            _app_options.AppOptions.get("viewOnLoad");
                        this._initializePdfHistory({
                            fingerprint: pdfDocument.fingerprint,
                            viewOnLoad,
                            initialDest: openAction?.dest,
                        });

                        const initialBookmark = this.initialBookmark;

                        const zoom =
                            _app_options.AppOptions.get("defaultZoomValue");

                        let hash = zoom ? `zoom=${zoom}` : null;
                        let rotation = null;

                        let sidebarView =
                            _app_options.AppOptions.get("sidebarViewOnLoad");

                        let scrollMode =
                            _app_options.AppOptions.get("scrollModeOnLoad");

                        let spreadMode =
                            _app_options.AppOptions.get("spreadModeOnLoad");

                        if (stored.page && viewOnLoad !== ViewOnLoad.INITIAL) {
                            hash =
                                `page=${stored.page}&zoom=${
                                    zoom || stored.zoom
                                },` +
                                `${stored.scrollLeft},${stored.scrollTop}`;
                            rotation = parseInt(stored.rotation, 10);

                            if (sidebarView === _ui_utils.SidebarView.UNKNOWN) {
                                sidebarView = stored.sidebarView | 0;
                            }

                            if (scrollMode === _ui_utils.ScrollMode.UNKNOWN) {
                                scrollMode = stored.scrollMode | 0;
                            }

                            if (spreadMode === _ui_utils.SpreadMode.UNKNOWN) {
                                spreadMode = stored.spreadMode | 0;
                            }
                        }

                        if (
                            pageMode &&
                            sidebarView === _ui_utils.SidebarView.UNKNOWN
                        ) {
                            sidebarView = (0,
                            _ui_utils.apiPageModeToSidebarView)(pageMode);
                        }

                        if (
                            pageLayout &&
                            spreadMode === _ui_utils.SpreadMode.UNKNOWN
                        ) {
                            spreadMode = (0,
                            _ui_utils.apiPageLayoutToSpreadMode)(pageLayout);
                        }
                        this.setInitialView(hash, {
                            rotation,
                            sidebarView,
                            scrollMode,
                            spreadMode,
                        });
                        this.eventBus.dispatch("documentinit", {
                            source: this,
                        });

                        if (!this.isViewerEmbedded) {
                            pdfViewer.focus();
                        }

                        this._initializePermissions(pdfDocument);

                        await Promise.race([
                            pagesPromise,
                            new Promise((resolve) => {
                                setTimeout(resolve, FORCE_PAGES_LOADED_TIMEOUT);
                            }),
                        ]);

                        if (!initialBookmark && !hash) {
                            return;
                        }

                        if (pdfViewer.hasEqualPageSizes) {
                            return;
                        }

                        this.initialBookmark = initialBookmark;
                        pdfViewer.currentScaleValue =
                            pdfViewer.currentScaleValue;
                        this.setInitialView(hash);
                    }
                )
                .catch(() => {
                    this.setInitialView();
                })
                .then(function () {
                    pdfViewer.update();
                });
        });
        pagesPromise.then(() => {
            this._unblockDocumentLoadEvent();

            this._initializeAutoPrint(pdfDocument, openActionPromise);
        });
        onePageRendered.then(() => {
            pdfDocument.getOutline().then((outline) => {
                this.pdfOutlineViewer.render({
                    outline,
                    pdfDocument,
                });
            });
            pdfDocument.getAttachments().then((attachments) => {
                this.pdfAttachmentViewer.render({
                    attachments,
                });
            });
            pdfViewer.optionalContentConfigPromise.then(
                (optionalContentConfig) => {
                    this.pdfLayerViewer.render({
                        optionalContentConfig,
                        pdfDocument,
                    });
                }
            );

            if ("requestIdleCallback" in window) {
                const callback = window.requestIdleCallback(
                    () => {
                        this._collectTelemetry(pdfDocument);

                        this._idleCallbacks.delete(callback);
                    },
                    {
                        timeout: 1000,
                    }
                );

                this._idleCallbacks.add(callback);
            }
        });

        this._initializePageLabels(pdfDocument);

        this._initializeMetadata(pdfDocument);
    },

    async _scriptingDocProperties(pdfDocument) {
        if (!this.documentInfo) {
            await new Promise((resolve) => {
                this.eventBus._on("metadataloaded", resolve, {
                    once: true,
                });
            });

            if (pdfDocument !== this.pdfDocument) {
                return null;
            }
        }

        if (!this._contentLength) {
            await new Promise((resolve) => {
                this.eventBus._on("documentloaded", resolve, {
                    once: true,
                });
            });

            if (pdfDocument !== this.pdfDocument) {
                return null;
            }
        }

        return {
            ...this.documentInfo,
            baseURL: this.baseUrl,
            filesize: this._contentLength,
            filename: this._docFilename,
            metadata: this.metadata?.getRaw(),
            authors: this.metadata?.get("dc:creator"),
            numPages: this.pagesCount,
            URL: this.url,
        };
    },

    async _collectTelemetry(pdfDocument) {
        const markInfo = await this.pdfDocument.getMarkInfo();

        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        const tagged = markInfo?.Marked || false;
        this.externalServices.reportTelemetry({
            type: "tagged",
            tagged,
        });
    },

    async _initializeAutoPrint(pdfDocument, openActionPromise) {
        const [openAction, javaScript] = await Promise.all([
            openActionPromise,
            !this.pdfViewer.enableScripting
                ? pdfDocument.getJavaScript()
                : null,
        ]);

        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        let triggerAutoPrint = false;

        if (openAction?.action === "Print") {
            triggerAutoPrint = true;
        }

        if (javaScript) {
            javaScript.some((js) => {
                if (!js) {
                    return false;
                }

                console.warn("Warning: JavaScript is not supported");

                this._delayedFallback(_pdf.UNSUPPORTED_FEATURES.javaScript);

                return true;
            });

            if (!triggerAutoPrint) {
                for (const js of javaScript) {
                    if (js && _ui_utils.AutoPrintRegExp.test(js)) {
                        triggerAutoPrint = true;
                        break;
                    }
                }
            }
        }

        if (triggerAutoPrint) {
            this.triggerPrinting();
        }
    },

    async _initializeMetadata(pdfDocument) {
        const { info, metadata, contentDispositionFilename, contentLength } =
            await pdfDocument.getMetadata();

        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        this.documentInfo = info;
        this.metadata = metadata;
        this._contentDispositionFilename ??
            (this._contentDispositionFilename = contentDispositionFilename);
        this._contentLength ?? (this._contentLength = contentLength);
        let pdfTitle = info?.Title;
        const metadataTitle = metadata?.get("dc:title");

        if (metadataTitle) {
            if (
                metadataTitle !== "Untitled" &&
                !/[\uFFF0-\uFFFF]/g.test(metadataTitle)
            ) {
                pdfTitle = metadataTitle;
            }
        }

        if (pdfTitle) {
            this.setTitle(
                `${pdfTitle} - ${contentDispositionFilename || document.title}`
            );
        } else if (contentDispositionFilename) {
            this.setTitle(contentDispositionFilename);
        }

        if (
            info.IsXFAPresent &&
            !info.IsAcroFormPresent &&
            !pdfDocument.isPureXfa
        ) {
            console.warn("Warning: XFA is not supported");

            this._delayedFallback(_pdf.UNSUPPORTED_FEATURES.forms);
        } else if (
            (info.IsAcroFormPresent || info.IsXFAPresent) &&
            !this.pdfViewer.renderInteractiveForms
        ) {
            console.warn("Warning: Interactive form support is not enabled");

            this._delayedFallback(_pdf.UNSUPPORTED_FEATURES.forms);
        }

        let versionId = "other";

        if (KNOWN_VERSIONS.includes(info.PDFFormatVersion)) {
            versionId = `v${info.PDFFormatVersion.replace(".", "_")}`;
        }

        let generatorId = "other";

        if (info.Producer) {
            const producer = info.Producer.toLowerCase();
            KNOWN_GENERATORS.some(function (generator) {
                if (!producer.includes(generator)) {
                    return false;
                }

                generatorId = generator.replace(/[ .-]/g, "_");
                return true;
            });
        }

        let formType = null;

        if (info.IsXFAPresent) {
            formType = "xfa";
        } else if (info.IsAcroFormPresent) {
            formType = "acroform";
        }

        this.externalServices.reportTelemetry({
            type: "documentInfo",
            version: versionId,
            generator: generatorId,
            formType,
        });
        this.eventBus.dispatch("metadataloaded", {
            source: this,
        });
    },

    async _initializePageLabels(pdfDocument) {
        const labels = await pdfDocument.getPageLabels();

        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        if (!labels || _app_options.AppOptions.get("disablePageLabels")) {
            return;
        }

        const numLabels = labels.length;

        if (numLabels !== this.pagesCount) {
            console.error(
                "The number of Page Labels does not match the number of pages in the document."
            );
            return;
        }

        let i = 0;

        while (i < numLabels && labels[i] === (i + 1).toString()) {
            i++;
        }

        if (i === numLabels) {
            return;
        }

        const { pdfViewer, pdfThumbnailViewer, toolbar } = this;
        pdfViewer.setPageLabels(labels);
        pdfThumbnailViewer.setPageLabels(labels);
        toolbar.setPagesCount(numLabels, true);
        toolbar.setPageNumber(
            pdfViewer.currentPageNumber,
            pdfViewer.currentPageLabel
        );
    },

    _initializePdfHistory({ fingerprint, viewOnLoad, initialDest = null }) {
        if (
            this.isViewerEmbedded ||
            _app_options.AppOptions.get("disableHistory")
        ) {
            return;
        }

        this.pdfHistory.initialize({
            fingerprint,
            resetHistory: viewOnLoad === ViewOnLoad.INITIAL,
            updateUrl: _app_options.AppOptions.get("historyUpdateUrl"),
        });

        if (this.pdfHistory.initialBookmark) {
            //this.initialBookmark = this.pdfHistory.initialBookmark;
            this.initialRotation = this.pdfHistory.initialRotation;
        }

        if (
            initialDest &&
            !this.initialBookmark &&
            viewOnLoad === ViewOnLoad.UNKNOWN
        ) {
            this.initialBookmark = JSON.stringify(initialDest);
            this.pdfHistory.push({
                explicitDest: initialDest,
                pageNumber: null,
            });
        }
    },

    async _initializePermissions(pdfDocument) {
        const permissions = await pdfDocument.getPermissions();

        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        if (!permissions || !_app_options.AppOptions.get("enablePermissions")) {
            return;
        }

        if (!permissions.includes(_pdf.PermissionFlag.COPY)) {
            this.appConfig.viewerContainer.classList.add(
                ENABLE_PERMISSIONS_CLASS
            );
        }
    },

    _initializeAnnotationStorageCallbacks(pdfDocument) {
        if (pdfDocument !== this.pdfDocument) {
            return;
        }

        const { annotationStorage } = pdfDocument;

        annotationStorage.onSetModified = () => {
            window.addEventListener("beforeunload", beforeUnload);
            this._annotationStorageModified = true;
        };

        annotationStorage.onResetModified = () => {
            window.removeEventListener("beforeunload", beforeUnload);
            delete this._annotationStorageModified;
        };
    },

    setInitialView(
        storedHash,
        { rotation, sidebarView, scrollMode, spreadMode } = {}
    ) {
        const setRotation = (angle) => {
            if ((0, _ui_utils.isValidRotation)(angle)) {
                this.pdfViewer.pagesRotation = angle;
            }
        };

        const setViewerModes = (scroll, spread) => {
            if ((0, _ui_utils.isValidScrollMode)(scroll)) {
                this.pdfViewer.scrollMode = scroll;
            }

            if ((0, _ui_utils.isValidSpreadMode)(spread)) {
                this.pdfViewer.spreadMode = spread;
            }
        };

        this.isInitialViewSet = true;
        this.pdfSidebar.setInitialView(sidebarView);
        setViewerModes(scrollMode, spreadMode);

        if (this.initialBookmark) {
            setRotation(this.initialRotation);
            delete this.initialRotation;
            this.pdfLinkService.setHash(this.initialBookmark);
            //this.initialBookmark = null;
        } else if (storedHash) {
            setRotation(rotation);
            this.pdfLinkService.setHash(storedHash);
        }

        this.toolbar.setPageNumber(
            this.pdfViewer.currentPageNumber,
            this.pdfViewer.currentPageLabel
        );
        this.secondaryToolbar.setPageNumber(this.pdfViewer.currentPageNumber);

        if (!this.pdfViewer.currentScaleValue) {
            this.pdfViewer.currentScaleValue = _ui_utils.DEFAULT_SCALE_VALUE;
        }
    },

    _cleanup() {
        if (!this.pdfDocument) {
            return;
        }

        this.pdfViewer.cleanup();
        this.pdfThumbnailViewer.cleanup();
        this.pdfDocument.cleanup(
            this.pdfViewer.renderer === _ui_utils.RendererType.SVG
        );
    },

    forceRendering() {
        this.pdfRenderingQueue.printing = !!this.printService;
        this.pdfRenderingQueue.isThumbnailViewEnabled =
            this.pdfSidebar.isThumbnailViewVisible;
        this.pdfRenderingQueue.renderHighestPriority();
    },

    beforePrint() {
        this.pdfScriptingManager.dispatchWillPrint();

        if (this.printService) {
            return;
        }

        if (!this.supportsPrinting) {
            this.l10n.get("printing_not_supported").then((msg) => {
                this._otherError(msg);
            });
            return;
        }

        if (!this.pdfViewer.pageViewsReady) {
            this.l10n.get("printing_not_ready").then((msg) => {
                window.alert(msg);
            });
            return;
        }

        const pagesOverview = this.pdfViewer.getPagesOverview();
        const printContainer = this.appConfig.printContainer;

        const printResolution = _app_options.AppOptions.get("printResolution");

        const optionalContentConfigPromise =
            this.pdfViewer.optionalContentConfigPromise;
        const printService = PDFPrintServiceFactory.instance.createPrintService(
            this.pdfDocument,
            pagesOverview,
            printContainer,
            printResolution,
            optionalContentConfigPromise,
            this.l10n
        );
        this.printService = printService;
        this.forceRendering();
        printService.layout();
        this.externalServices.reportTelemetry({
            type: "print",
        });
    },

    afterPrint() {
        this.pdfScriptingManager.dispatchDidPrint();

        if (this.printService) {
            this.printService.destroy();
            this.printService = null;

            if (this.pdfDocument) {
                this.pdfDocument.annotationStorage.resetModified();
            }
        }

        this.forceRendering();
    },

    rotatePages(delta) {
        this.pdfViewer.pagesRotation += delta;
    },

    requestPresentationMode() {
        if (!this.pdfPresentationMode) {
            return;
        }

        this.pdfPresentationMode.request();
    },

    triggerPrinting() {
        if (!this.supportsPrinting) {
            return;
        }

        window.print();
    },

    bindEvents() {
        const { eventBus, _boundEvents } = this;
        _boundEvents.beforePrint = this.beforePrint.bind(this);
        _boundEvents.afterPrint = this.afterPrint.bind(this);

        eventBus._on("resize", webViewerResize);

        eventBus._on("hashchange", webViewerHashchange);

        eventBus._on("beforeprint", _boundEvents.beforePrint);

        eventBus._on("afterprint", _boundEvents.afterPrint);

        eventBus._on("pagerendered", webViewerPageRendered);

        eventBus._on("updateviewarea", webViewerUpdateViewarea);

        eventBus._on("pagechanging", webViewerPageChanging);

        eventBus._on("scalechanging", webViewerScaleChanging);

        eventBus._on("rotationchanging", webViewerRotationChanging);

        eventBus._on("sidebarviewchanged", webViewerSidebarViewChanged);

        eventBus._on("pagemode", webViewerPageMode);

        eventBus._on("namedaction", webViewerNamedAction);

        eventBus._on(
            "presentationmodechanged",
            webViewerPresentationModeChanged
        );

        eventBus._on("presentationmode", webViewerPresentationMode);

        eventBus._on("print", webViewerPrint);

        eventBus._on("download", webViewerDownload);

        eventBus._on("save", webViewerSave);

        eventBus._on("firstpage", webViewerFirstPage);

        eventBus._on("lastpage", webViewerLastPage);

        eventBus._on("nextpage", webViewerNextPage);

        eventBus._on("previouspage", webViewerPreviousPage);

        eventBus._on("zoomin", webViewerZoomIn);

        eventBus._on("zoomout", webViewerZoomOut);

        eventBus._on("zoomreset", webViewerZoomReset);

        eventBus._on("pagenumberchanged", webViewerPageNumberChanged);

        eventBus._on("scalechanged", webViewerScaleChanged);

        eventBus._on("rotatecw", webViewerRotateCw);

        eventBus._on("rotateccw", webViewerRotateCcw);

        eventBus._on("optionalcontentconfig", webViewerOptionalContentConfig);

        eventBus._on("switchscrollmode", webViewerSwitchScrollMode);

        eventBus._on("scrollmodechanged", webViewerScrollModeChanged);

        eventBus._on("switchspreadmode", webViewerSwitchSpreadMode);

        eventBus._on("spreadmodechanged", webViewerSpreadModeChanged);

        eventBus._on("documentproperties", webViewerDocumentProperties);

        eventBus._on("find", webViewerFind);

        eventBus._on("findfromurlhash", webViewerFindFromUrlHash);

        eventBus._on("updatefindmatchescount", webViewerUpdateFindMatchesCount);

        eventBus._on("updatefindcontrolstate", webViewerUpdateFindControlState);

        if (_app_options.AppOptions.get("pdfBug")) {
            _boundEvents.reportPageStatsPDFBug = reportPageStatsPDFBug;

            eventBus._on("pagerendered", _boundEvents.reportPageStatsPDFBug);

            eventBus._on("pagechanging", _boundEvents.reportPageStatsPDFBug);
        }

        eventBus._on("fileinputchange", webViewerFileInputChange);

        eventBus._on("openfile", webViewerOpenFile);
    },

    bindWindowEvents() {
        const { eventBus, _boundEvents } = this;

        _boundEvents.windowResize = () => {
            eventBus.dispatch("resize", {
                source: window,
            });
        };

        _boundEvents.windowHashChange = () => {
            eventBus.dispatch("hashchange", {
                source: window,
                hash: document.location.hash.substring(1),
            });
        };

        _boundEvents.windowBeforePrint = () => {
            eventBus.dispatch("beforeprint", {
                source: window,
            });
        };

        _boundEvents.windowAfterPrint = () => {
            eventBus.dispatch("afterprint", {
                source: window,
            });
        };

        _boundEvents.windowUpdateFromSandbox = (event) => {
            eventBus.dispatch("updatefromsandbox", {
                source: window,
                detail: event.detail,
            });
        };

        window.addEventListener("visibilitychange", webViewerVisibilityChange);
        window.addEventListener("wheel", webViewerWheel, {
            passive: false,
        });
        window.addEventListener("touchstart", webViewerTouchStart, {
            passive: false,
        });
        window.addEventListener("click", webViewerClick);
        window.addEventListener("keydown", webViewerKeyDown);
        window.addEventListener("keyup", webViewerKeyUp);
        window.addEventListener("resize", _boundEvents.windowResize);
        window.addEventListener("hashchange", _boundEvents.windowHashChange);
        window.addEventListener("beforeprint", _boundEvents.windowBeforePrint);
        window.addEventListener("afterprint", _boundEvents.windowAfterPrint);
        window.addEventListener(
            "updatefromsandbox",
            _boundEvents.windowUpdateFromSandbox
        );
    },

    unbindEvents() {
        const { eventBus, _boundEvents } = this;

        eventBus._off("resize", webViewerResize);

        eventBus._off("hashchange", webViewerHashchange);

        eventBus._off("beforeprint", _boundEvents.beforePrint);

        eventBus._off("afterprint", _boundEvents.afterPrint);

        eventBus._off("pagerendered", webViewerPageRendered);

        eventBus._off("updateviewarea", webViewerUpdateViewarea);

        eventBus._off("pagechanging", webViewerPageChanging);

        eventBus._off("scalechanging", webViewerScaleChanging);

        eventBus._off("rotationchanging", webViewerRotationChanging);

        eventBus._off("sidebarviewchanged", webViewerSidebarViewChanged);

        eventBus._off("pagemode", webViewerPageMode);

        eventBus._off("namedaction", webViewerNamedAction);

        eventBus._off(
            "presentationmodechanged",
            webViewerPresentationModeChanged
        );

        eventBus._off("presentationmode", webViewerPresentationMode);

        eventBus._off("print", webViewerPrint);

        eventBus._off("download", webViewerDownload);

        eventBus._off("save", webViewerSave);

        eventBus._off("firstpage", webViewerFirstPage);

        eventBus._off("lastpage", webViewerLastPage);

        eventBus._off("nextpage", webViewerNextPage);

        eventBus._off("previouspage", webViewerPreviousPage);

        eventBus._off("zoomin", webViewerZoomIn);

        eventBus._off("zoomout", webViewerZoomOut);

        eventBus._off("zoomreset", webViewerZoomReset);

        eventBus._off("pagenumberchanged", webViewerPageNumberChanged);

        eventBus._off("scalechanged", webViewerScaleChanged);

        eventBus._off("rotatecw", webViewerRotateCw);

        eventBus._off("rotateccw", webViewerRotateCcw);

        eventBus._off("optionalcontentconfig", webViewerOptionalContentConfig);

        eventBus._off("switchscrollmode", webViewerSwitchScrollMode);

        eventBus._off("scrollmodechanged", webViewerScrollModeChanged);

        eventBus._off("switchspreadmode", webViewerSwitchSpreadMode);

        eventBus._off("spreadmodechanged", webViewerSpreadModeChanged);

        eventBus._off("documentproperties", webViewerDocumentProperties);

        eventBus._off("find", webViewerFind);

        eventBus._off("findfromurlhash", webViewerFindFromUrlHash);

        eventBus._off(
            "updatefindmatchescount",
            webViewerUpdateFindMatchesCount
        );

        eventBus._off(
            "updatefindcontrolstate",
            webViewerUpdateFindControlState
        );

        if (_boundEvents.reportPageStatsPDFBug) {
            eventBus._off("pagerendered", _boundEvents.reportPageStatsPDFBug);

            eventBus._off("pagechanging", _boundEvents.reportPageStatsPDFBug);

            _boundEvents.reportPageStatsPDFBug = null;
        }

        eventBus._off("fileinputchange", webViewerFileInputChange);

        eventBus._off("openfile", webViewerOpenFile);

        _boundEvents.beforePrint = null;
        _boundEvents.afterPrint = null;
    },

    unbindWindowEvents() {
        const { _boundEvents } = this;
        window.removeEventListener(
            "visibilitychange",
            webViewerVisibilityChange
        );
        window.removeEventListener("wheel", webViewerWheel, {
            passive: false,
        });
        window.removeEventListener("touchstart", webViewerTouchStart, {
            passive: false,
        });
        window.removeEventListener("click", webViewerClick);
        window.removeEventListener("keydown", webViewerKeyDown);
        window.removeEventListener("keyup", webViewerKeyUp);
        window.removeEventListener("resize", _boundEvents.windowResize);
        window.removeEventListener("hashchange", _boundEvents.windowHashChange);
        window.removeEventListener(
            "beforeprint",
            _boundEvents.windowBeforePrint
        );
        window.removeEventListener("afterprint", _boundEvents.windowAfterPrint);
        window.removeEventListener(
            "updatefromsandbox",
            _boundEvents.windowUpdateFromSandbox
        );
        _boundEvents.windowResize = null;
        _boundEvents.windowHashChange = null;
        _boundEvents.windowBeforePrint = null;
        _boundEvents.windowAfterPrint = null;
        _boundEvents.windowUpdateFromSandbox = null;
    },

    accumulateWheelTicks(ticks) {
        if (
            (this._wheelUnusedTicks > 0 && ticks < 0) ||
            (this._wheelUnusedTicks < 0 && ticks > 0)
        ) {
            this._wheelUnusedTicks = 0;
        }

        this._wheelUnusedTicks += ticks;
        const wholeTicks =
            Math.sign(this._wheelUnusedTicks) *
            Math.floor(Math.abs(this._wheelUnusedTicks));
        this._wheelUnusedTicks -= wholeTicks;
        return wholeTicks;
    },

    _unblockDocumentLoadEvent() {
        if (document.blockUnblockOnload) {
            document.blockUnblockOnload(false);
        }

        this._unblockDocumentLoadEvent = () => {};
    },

    get scriptingReady() {
        return this.pdfScriptingManager.ready;
    },
};
exports.PDFViewerApplication = PDFViewerApplication;
let validateFileURL;
{
    const HOSTED_VIEWER_ORIGINS = [
        "null",
        "http://mozilla.github.io",
        "https://mozilla.github.io",
    ];

    validateFileURL = function (file) {
        if (file === undefined) {
            return;
        }

        try {
            const viewerOrigin = new URL(window.location.href).origin || "null";

            if (HOSTED_VIEWER_ORIGINS.includes(viewerOrigin)) {
                return;
            }

            const { origin, protocol } = new URL(file, window.location.href);

            if (origin !== viewerOrigin && protocol !== "blob:") {
                throw new Error("file origin does not match viewer's");
            }
        } catch (ex) {
            PDFViewerApplication.l10n.get("loading_error").then((msg) => {
                PDFViewerApplication._documentError(msg, {
                    message: ex?.message,
                });
            });
            throw ex;
        }
    };
}

async function loadFakeWorker() {
    if (!_pdf.GlobalWorkerOptions.workerSrc) {
        _pdf.GlobalWorkerOptions.workerSrc =
            _app_options.AppOptions.get("workerSrc");
    }

    return (0, _pdf.loadScript)(_pdf.PDFWorker.getWorkerSrc());
}

function loadAndEnablePDFBug(enabledTabs) {
    const appConfig = PDFViewerApplication.appConfig;
    return (0, _pdf.loadScript)(appConfig.debuggerScriptPath).then(function () {
        PDFBug.enable(enabledTabs);
        PDFBug.init(
            {
                OPS: _pdf.OPS,
            },
            appConfig.mainContainer
        );
    });
}

function reportPageStatsPDFBug({ pageNumber }) {
    if (typeof Stats === "undefined" || !Stats.enabled) {
        return;
    }

    const pageView = PDFViewerApplication.pdfViewer.getPageView(pageNumber - 1);
    const pageStats = pageView?.pdfPage?.stats;

    if (!pageStats) {
        return;
    }

    Stats.add(pageNumber, pageStats);
}

function webViewerInitialized() {
    const appConfig = PDFViewerApplication.appConfig;
    let file;
    const queryString = document.location.search.substring(1);
    const params = (0, _ui_utils.parseQueryString)(queryString);
    file =
        "file" in params
            ? params.file
            : _app_options.AppOptions.get("defaultUrl");
    validateFileURL(file);
    const fileInput = document.createElement("input");
    fileInput.id = appConfig.openFileInputName;
    fileInput.className = "fileInput";
    fileInput.setAttribute("type", "file");
    fileInput.oncontextmenu = _ui_utils.noContextMenuHandler;
    document.body.appendChild(fileInput);

    if (
        !window.File ||
        !window.FileReader ||
        !window.FileList ||
        !window.Blob
    ) {
        appConfig.toolbar.openFile.hidden = true;
        appConfig.secondaryToolbar.openFileButton.hidden = true;
    } else {
        fileInput.value = null;
    }

    fileInput.addEventListener("change", function (evt) {
        const files = evt.target.files;

        if (!files || files.length === 0) {
            return;
        }

        PDFViewerApplication.eventBus.dispatch("fileinputchange", {
            source: this,
            fileInput: evt.target,
        });
    });
    appConfig.mainContainer.addEventListener("dragover", function (evt) {
        evt.preventDefault();
        evt.dataTransfer.dropEffect = "move";
    });
    appConfig.mainContainer.addEventListener("drop", function (evt) {
        evt.preventDefault();
        const files = evt.dataTransfer.files;

        if (!files || files.length === 0) {
            return;
        }

        PDFViewerApplication.eventBus.dispatch("fileinputchange", {
            source: this,
            fileInput: evt.dataTransfer,
        });
    });

    if (!PDFViewerApplication.supportsDocumentFonts) {
        _app_options.AppOptions.set("disableFontFace", true);

        PDFViewerApplication.l10n.get("web_fonts_disabled").then((msg) => {
            console.warn(msg);
        });
    }

    if (!PDFViewerApplication.supportsPrinting) {
        appConfig.toolbar.print.classList.add("hidden");
        appConfig.secondaryToolbar.printButton.classList.add("hidden");
    }

    if (!PDFViewerApplication.supportsFullscreen) {
        appConfig.toolbar.presentationModeButton.classList.add("hidden");
        appConfig.secondaryToolbar.presentationModeButton.classList.add(
            "hidden"
        );
    }

    if (PDFViewerApplication.supportsIntegratedFind) {
        appConfig.toolbar.viewFind.classList.add("hidden");
    }

    appConfig.mainContainer.addEventListener(
        "transitionend",
        function (evt) {
            if (evt.target === this) {
                PDFViewerApplication.eventBus.dispatch("resize", {
                    source: this,
                });
            }
        },
        true
    );

    try {
        webViewerOpenFileViaURL(file);
    } catch (reason) {
        PDFViewerApplication.l10n.get("loading_error").then((msg) => {
            PDFViewerApplication._documentError(msg, reason);
        });
    }
}

function webViewerOpenFileViaURL(file) {
    if (file) {
        PDFViewerApplication.open(file);
    }
}

function webViewerResetPermissions() {
    const { appConfig } = PDFViewerApplication;

    if (!appConfig) {
        return;
    }

    appConfig.viewerContainer.classList.remove(ENABLE_PERMISSIONS_CLASS);
}

function webViewerPageRendered({ pageNumber, timestamp, error }) {
    if (pageNumber === PDFViewerApplication.page) {
        PDFViewerApplication.toolbar.updateLoadingIndicatorState(false);
    }

    if (PDFViewerApplication.pdfSidebar.isThumbnailViewVisible) {
        const pageView = PDFViewerApplication.pdfViewer.getPageView(
            pageNumber - 1
        );
        const thumbnailView =
            PDFViewerApplication.pdfThumbnailViewer.getThumbnail(
                pageNumber - 1
            );

        if (pageView && thumbnailView) {
            thumbnailView.setImage(pageView);
        }
    }

    if (error) {
        PDFViewerApplication.l10n.get("rendering_error").then((msg) => {
            PDFViewerApplication._otherError(msg, error);
        });
    }

    PDFViewerApplication.externalServices.reportTelemetry({
        type: "pageInfo",
        timestamp,
    });
    PDFViewerApplication.pdfDocument.getStats().then(function (stats) {
        PDFViewerApplication.externalServices.reportTelemetry({
            type: "documentStats",
            stats,
        });
    });
}

function webViewerPageMode({ mode }) {
    let view;

    switch (mode) {
        case "thumbs":
            view = _ui_utils.SidebarView.THUMBS;
            break;

        case "bookmarks":
        case "outline":
            view = _ui_utils.SidebarView.OUTLINE;
            break;

        case "attachments":
            view = _ui_utils.SidebarView.ATTACHMENTS;
            break;

        case "layers":
            view = _ui_utils.SidebarView.LAYERS;
            break;

        case "none":
            view = _ui_utils.SidebarView.NONE;
            break;

        default:
            console.error('Invalid "pagemode" hash parameter: ' + mode);
            return;
    }

    PDFViewerApplication.pdfSidebar.switchView(view, true);
}

function webViewerNamedAction(evt) {
    switch (evt.action) {
        case "GoToPage":
            PDFViewerApplication.appConfig.toolbar.pageNumber.select();
            break;

        case "Find":
            if (!PDFViewerApplication.supportsIntegratedFind) {
                PDFViewerApplication.findBar.toggle();
            }

            break;

        case "Print":
            PDFViewerApplication.triggerPrinting();
            break;

        case "SaveAs":
            webViewerSave();
            break;
    }
}

function webViewerPresentationModeChanged(evt) {
    PDFViewerApplication.pdfViewer.presentationModeState = evt.state;
}

function webViewerSidebarViewChanged(evt) {
    PDFViewerApplication.pdfRenderingQueue.isThumbnailViewEnabled =
        PDFViewerApplication.pdfSidebar.isThumbnailViewVisible;
    const store = PDFViewerApplication.store;

    if (store && PDFViewerApplication.isInitialViewSet) {
        store.set("sidebarView", evt.view).catch(function () {});
    }
}

function webViewerUpdateViewarea(evt) {
    const location = evt.location,
        store = PDFViewerApplication.store;

    if (store && PDFViewerApplication.isInitialViewSet) {
        store
            .setMultiple({
                page: location.pageNumber,
                zoom: location.scale,
                scrollLeft: location.left,
                scrollTop: location.top,
                rotation: location.rotation,
            })
            .catch(function () {});
    }

    const href = PDFViewerApplication.pdfLinkService.getAnchorUrl(
        location.pdfOpenParams
    );
    PDFViewerApplication.appConfig.toolbar.viewBookmark.href = href;
    PDFViewerApplication.appConfig.secondaryToolbar.viewBookmarkButton.href =
        href;
    const currentPage = PDFViewerApplication.pdfViewer.getPageView(
        PDFViewerApplication.page - 1
    );
    const loading =
        currentPage?.renderingState !==
        _pdf_rendering_queue.RenderingStates.FINISHED;
    PDFViewerApplication.toolbar.updateLoadingIndicatorState(loading);
}

function webViewerScrollModeChanged(evt) {
    const store = PDFViewerApplication.store;

    if (store && PDFViewerApplication.isInitialViewSet) {
        store.set("scrollMode", evt.mode).catch(function () {});
    }
}

function webViewerSpreadModeChanged(evt) {
    const store = PDFViewerApplication.store;

    if (store && PDFViewerApplication.isInitialViewSet) {
        store.set("spreadMode", evt.mode).catch(function () {});
    }
}

function webViewerResize() {
    const { pdfDocument, pdfViewer } = PDFViewerApplication;

    if (!pdfDocument) {
        return;
    }

    const currentScaleValue = pdfViewer.currentScaleValue;

    if (
        currentScaleValue === "auto" ||
        currentScaleValue === "page-fit" ||
        currentScaleValue === "page-width"
    ) {
        pdfViewer.currentScaleValue = currentScaleValue;
    }

    pdfViewer.update();
}

function webViewerHashchange(evt) {
    const hash = evt.hash;

    if (!hash) {
        return;
    }

    if (!PDFViewerApplication.isInitialViewSet) {
        PDFViewerApplication.initialBookmark = hash;
    } else if (!PDFViewerApplication.pdfHistory.popStateInProgress) {
        PDFViewerApplication.pdfLinkService.setHash(hash);
    }
}

let webViewerFileInputChange, webViewerOpenFile;
{
    webViewerFileInputChange = function (evt) {
        if (PDFViewerApplication.pdfViewer?.isInPresentationMode) {
            return;
        }

        const file = evt.fileInput.files[0];

        if (
            !_viewer_compatibility.viewerCompatibilityParams
                .disableCreateObjectURL
        ) {
            let url = URL.createObjectURL(file);

            if (file.name) {
                url = {
                    url,
                    originalUrl: file.name,
                };
            }

            PDFViewerApplication.open(url);
        } else {
            PDFViewerApplication.setTitleUsingUrl(file.name);
            const fileReader = new FileReader();

            fileReader.onload = function webViewerChangeFileReaderOnload(
                event
            ) {
                const buffer = event.target.result;
                PDFViewerApplication.open(new Uint8Array(buffer));
            };

            fileReader.readAsArrayBuffer(file);
        }

        const appConfig = PDFViewerApplication.appConfig;
        appConfig.toolbar.viewBookmark.hidden = true;
        appConfig.secondaryToolbar.viewBookmarkButton.hidden = true;
        appConfig.toolbar.download.hidden = true;
        appConfig.secondaryToolbar.downloadButton.hidden = true;
    };

    webViewerOpenFile = function (evt) {
        const openFileInputName =
            PDFViewerApplication.appConfig.openFileInputName;
        document.getElementById(openFileInputName).click();
    };
}

function webViewerPresentationMode() {
    PDFViewerApplication.requestPresentationMode();
}

function webViewerPrint() {
    PDFViewerApplication.triggerPrinting();
}

function webViewerDownload() {
    PDFViewerApplication.downloadOrSave({
        sourceEventType: "download",
    });
}

function webViewerSave() {
    PDFViewerApplication.downloadOrSave({
        sourceEventType: "save",
    });
}

function webViewerFirstPage() {
    if (PDFViewerApplication.pdfDocument) {
        PDFViewerApplication.page = 1;
    }
}

function webViewerLastPage() {
    if (PDFViewerApplication.pdfDocument) {
        PDFViewerApplication.page = PDFViewerApplication.pagesCount;
    }
}

function webViewerNextPage() {
    PDFViewerApplication.pdfViewer.nextPage();
}

function webViewerPreviousPage() {
    PDFViewerApplication.pdfViewer.previousPage();
}

function webViewerZoomIn() {
    PDFViewerApplication.zoomIn();
}

function webViewerZoomOut() {
    PDFViewerApplication.zoomOut();
}

function webViewerZoomReset() {
    PDFViewerApplication.zoomReset();
}

function webViewerPageNumberChanged(evt) {
    const pdfViewer = PDFViewerApplication.pdfViewer;

    if (evt.value !== "") {
        PDFViewerApplication.pdfLinkService.goToPage(evt.value);
    }

    if (
        evt.value !== pdfViewer.currentPageNumber.toString() &&
        evt.value !== pdfViewer.currentPageLabel
    ) {
        PDFViewerApplication.toolbar.setPageNumber(
            pdfViewer.currentPageNumber,
            pdfViewer.currentPageLabel
        );
    }
}

function webViewerScaleChanged(evt) {
    PDFViewerApplication.pdfViewer.currentScaleValue = evt.value;
}

function webViewerRotateCw() {
    PDFViewerApplication.rotatePages(90);
}

function webViewerRotateCcw() {
    PDFViewerApplication.rotatePages(-90);
}

function webViewerOptionalContentConfig(evt) {
    PDFViewerApplication.pdfViewer.optionalContentConfigPromise = evt.promise;
}

function webViewerSwitchScrollMode(evt) {
    PDFViewerApplication.pdfViewer.scrollMode = evt.mode;
}

function webViewerSwitchSpreadMode(evt) {
    PDFViewerApplication.pdfViewer.spreadMode = evt.mode;
}

function webViewerDocumentProperties() {
    PDFViewerApplication.pdfDocumentProperties.open();
}

function webViewerFind(evt) {
    PDFViewerApplication.findController.executeCommand("find" + evt.type, {
        query: evt.query,
        phraseSearch: evt.phraseSearch,
        caseSensitive: evt.caseSensitive,
        entireWord: evt.entireWord,
        highlightAll: evt.highlightAll,
        findPrevious: evt.findPrevious,
    });
}

function webViewerFindFromUrlHash(evt) {
    PDFViewerApplication.findController.executeCommand("find", {
        query: evt.query,
        phraseSearch: evt.phraseSearch,
        caseSensitive: false,
        entireWord: false,
        highlightAll: true,
        findPrevious: false,
    });
}

function webViewerUpdateFindMatchesCount({ matchesCount }) {
    if (PDFViewerApplication.supportsIntegratedFind) {
        PDFViewerApplication.externalServices.updateFindMatchesCount(
            matchesCount
        );
    } else {
        PDFViewerApplication.findBar.updateResultsCount(matchesCount);
    }
}

function webViewerUpdateFindControlState({
    state,
    previous,
    matchesCount,
    rawQuery,
}) {
    if (PDFViewerApplication.supportsIntegratedFind) {
        PDFViewerApplication.externalServices.updateFindControlState({
            result: state,
            findPrevious: previous,
            matchesCount,
            rawQuery,
        });
    } else {
        PDFViewerApplication.findBar.updateUIState(
            state,
            previous,
            matchesCount
        );
    }
}

function webViewerScaleChanging(evt) {
    PDFViewerApplication.toolbar.setPageScale(evt.presetValue, evt.scale);
    PDFViewerApplication.pdfViewer.update();
}

function webViewerRotationChanging(evt) {
    PDFViewerApplication.pdfThumbnailViewer.pagesRotation = evt.pagesRotation;
    PDFViewerApplication.forceRendering();
    PDFViewerApplication.pdfViewer.currentPageNumber = evt.pageNumber;
}

function webViewerPageChanging({ pageNumber, pageLabel }) {
    PDFViewerApplication.toolbar.setPageNumber(pageNumber, pageLabel);
    PDFViewerApplication.secondaryToolbar.setPageNumber(pageNumber);

    if (PDFViewerApplication.pdfSidebar.isThumbnailViewVisible) {
        PDFViewerApplication.pdfThumbnailViewer.scrollThumbnailIntoView(
            pageNumber
        );
    }
}

function webViewerVisibilityChange(evt) {
    if (document.visibilityState === "visible") {
        setZoomDisabledTimeout();
    }
}

let zoomDisabledTimeout = null;

function setZoomDisabledTimeout() {
    if (zoomDisabledTimeout) {
        clearTimeout(zoomDisabledTimeout);
    }

    zoomDisabledTimeout = setTimeout(function () {
        zoomDisabledTimeout = null;
    }, WHEEL_ZOOM_DISABLED_TIMEOUT);
}

function webViewerWheel(evt) {
    const { pdfViewer, supportedMouseWheelZoomModifierKeys } =
        PDFViewerApplication;

    if (pdfViewer.isInPresentationMode) {
        return;
    }

    if (
        (evt.ctrlKey && supportedMouseWheelZoomModifierKeys.ctrlKey) ||
        (evt.metaKey && supportedMouseWheelZoomModifierKeys.metaKey)
    ) {
        evt.preventDefault();

        if (zoomDisabledTimeout || document.visibilityState === "hidden") {
            return;
        }

        const previousScale = pdfViewer.currentScale;
        const delta = (0, _ui_utils.normalizeWheelEventDirection)(evt);
        let ticks = 0;

        if (
            evt.deltaMode === WheelEvent.DOM_DELTA_LINE ||
            evt.deltaMode === WheelEvent.DOM_DELTA_PAGE
        ) {
            if (Math.abs(delta) >= 1) {
                ticks = Math.sign(delta);
            } else {
                ticks = PDFViewerApplication.accumulateWheelTicks(delta);
            }
        } else {
            const PIXELS_PER_LINE_SCALE = 30;
            ticks = PDFViewerApplication.accumulateWheelTicks(
                delta / PIXELS_PER_LINE_SCALE
            );
        }

        if (ticks < 0) {
            PDFViewerApplication.zoomOut(-ticks);
        } else if (ticks > 0) {
            PDFViewerApplication.zoomIn(ticks);
        }

        const currentScale = pdfViewer.currentScale;

        if (previousScale !== currentScale) {
            const scaleCorrectionFactor = currentScale / previousScale - 1;
            const rect = pdfViewer.container.getBoundingClientRect();
            const dx = evt.clientX - rect.left;
            const dy = evt.clientY - rect.top;
            pdfViewer.container.scrollLeft += dx * scaleCorrectionFactor;
            pdfViewer.container.scrollTop += dy * scaleCorrectionFactor;
        }
    } else {
        setZoomDisabledTimeout();
    }
}

function webViewerTouchStart(evt) {
    if (evt.touches.length > 1) {
        evt.preventDefault();
    }
}

function webViewerClick(evt) {
    if (
        PDFViewerApplication.triggerDelayedFallback &&
        PDFViewerApplication.pdfViewer.containsElement(evt.target)
    ) {
        PDFViewerApplication.triggerDelayedFallback();
    }

    if (!PDFViewerApplication.secondaryToolbar.isOpen) {
        return;
    }

    const appConfig = PDFViewerApplication.appConfig;

    if (
        PDFViewerApplication.pdfViewer.containsElement(evt.target) ||
        (appConfig.toolbar.container.contains(evt.target) &&
            evt.target !== appConfig.secondaryToolbar.toggleButton)
    ) {
        PDFViewerApplication.secondaryToolbar.close();
    }
}

function webViewerKeyUp(evt) {
    if (evt.keyCode === 9) {
        if (PDFViewerApplication.triggerDelayedFallback) {
            PDFViewerApplication.triggerDelayedFallback();
        }
    }
}

function webViewerKeyDown(evt) {
    if (PDFViewerApplication.overlayManager.active) {
        return;
    }

    let handled = false,
        ensureViewerFocused = false;
    const cmd =
        (evt.ctrlKey ? 1 : 0) |
        (evt.altKey ? 2 : 0) |
        (evt.shiftKey ? 4 : 0) |
        (evt.metaKey ? 8 : 0);
    const pdfViewer = PDFViewerApplication.pdfViewer;
    const isViewerInPresentationMode = pdfViewer?.isInPresentationMode;

    if (cmd === 1 || cmd === 8 || cmd === 5 || cmd === 12) {
        switch (evt.keyCode) {
            case 70:
                if (
                    !PDFViewerApplication.supportsIntegratedFind &&
                    !evt.shiftKey
                ) {
                    PDFViewerApplication.findBar.open();
                    handled = true;
                }

                break;

            case 71:
                if (!PDFViewerApplication.supportsIntegratedFind) {
                    const findState = PDFViewerApplication.findController.state;

                    if (findState) {
                        PDFViewerApplication.findController.executeCommand(
                            "findagain",
                            {
                                query: findState.query,
                                phraseSearch: findState.phraseSearch,
                                caseSensitive: findState.caseSensitive,
                                entireWord: findState.entireWord,
                                highlightAll: findState.highlightAll,
                                findPrevious: cmd === 5 || cmd === 12,
                            }
                        );
                    }

                    handled = true;
                }

                break;

            case 61:
            case 107:
            case 187:
            case 171:
                if (!isViewerInPresentationMode) {
                    PDFViewerApplication.zoomIn();
                }

                handled = true;
                break;

            case 173:
            case 109:
            case 189:
                if (!isViewerInPresentationMode) {
                    PDFViewerApplication.zoomOut();
                }

                handled = true;
                break;

            case 48:
            case 96:
                if (!isViewerInPresentationMode) {
                    setTimeout(function () {
                        PDFViewerApplication.zoomReset();
                    });
                    handled = false;
                }

                break;

            case 38:
                if (
                    isViewerInPresentationMode ||
                    PDFViewerApplication.page > 1
                ) {
                    PDFViewerApplication.page = 1;
                    handled = true;
                    ensureViewerFocused = true;
                }

                break;

            case 40:
                if (
                    isViewerInPresentationMode ||
                    PDFViewerApplication.page < PDFViewerApplication.pagesCount
                ) {
                    PDFViewerApplication.page = PDFViewerApplication.pagesCount;
                    handled = true;
                    ensureViewerFocused = true;
                }

                break;
        }
    }

    const { eventBus } = PDFViewerApplication;

    if (cmd === 1 || cmd === 8) {
        switch (evt.keyCode) {
            case 83:
                eventBus.dispatch("download", {
                    source: window,
                });
                handled = true;
                break;

            case 79:
                {
                    eventBus.dispatch("openfile", {
                        source: window,
                    });
                    handled = true;
                }
                break;
        }
    }

    if (cmd === 3 || cmd === 10) {
        switch (evt.keyCode) {
            case 80:
                PDFViewerApplication.requestPresentationMode();
                handled = true;
                break;

            case 71:
                PDFViewerApplication.appConfig.toolbar.pageNumber.select();
                handled = true;
                break;
        }
    }

    if (handled) {
        if (ensureViewerFocused && !isViewerInPresentationMode) {
            pdfViewer.focus();
        }

        evt.preventDefault();
        return;
    }

    const curElement = (0, _ui_utils.getActiveOrFocusedElement)();
    const curElementTagName = curElement?.tagName.toUpperCase();

    if (
        curElementTagName === "INPUT" ||
        curElementTagName === "TEXTAREA" ||
        curElementTagName === "SELECT" ||
        curElement?.isContentEditable
    ) {
        if (evt.keyCode !== 27) {
            return;
        }
    }

    if (cmd === 0) {
        let turnPage = 0,
            turnOnlyIfPageFit = false;

        switch (evt.keyCode) {
            case 38:
            case 33:
                if (pdfViewer.isVerticalScrollbarEnabled) {
                    turnOnlyIfPageFit = true;
                }

                turnPage = -1;
                break;

            case 8:
                if (!isViewerInPresentationMode) {
                    turnOnlyIfPageFit = true;
                }

                turnPage = -1;
                break;

            case 37:
                if (pdfViewer.isHorizontalScrollbarEnabled) {
                    turnOnlyIfPageFit = true;
                }

            case 75:
            case 80:
                turnPage = -1;
                break;

            case 27:
                if (PDFViewerApplication.secondaryToolbar.isOpen) {
                    PDFViewerApplication.secondaryToolbar.close();
                    handled = true;
                }

                if (
                    !PDFViewerApplication.supportsIntegratedFind &&
                    PDFViewerApplication.findBar.opened
                ) {
                    PDFViewerApplication.findBar.close();
                    handled = true;
                }

                break;

            case 40:
            case 34:
                if (pdfViewer.isVerticalScrollbarEnabled) {
                    turnOnlyIfPageFit = true;
                }

                turnPage = 1;
                break;

            case 13:
            case 32:
                if (!isViewerInPresentationMode) {
                    turnOnlyIfPageFit = true;
                }

                turnPage = 1;
                break;

            case 39:
                if (pdfViewer.isHorizontalScrollbarEnabled) {
                    turnOnlyIfPageFit = true;
                }

            case 74:
            case 78:
                turnPage = 1;
                break;

            case 36:
                if (
                    isViewerInPresentationMode ||
                    PDFViewerApplication.page > 1
                ) {
                    PDFViewerApplication.page = 1;
                    handled = true;
                    ensureViewerFocused = true;
                }

                break;

            case 35:
                if (
                    isViewerInPresentationMode ||
                    PDFViewerApplication.page < PDFViewerApplication.pagesCount
                ) {
                    PDFViewerApplication.page = PDFViewerApplication.pagesCount;
                    handled = true;
                    ensureViewerFocused = true;
                }

                break;

            case 83:
                PDFViewerApplication.pdfCursorTools.switchTool(
                    _pdf_cursor_tools.CursorTool.SELECT
                );
                break;

            case 72:
                PDFViewerApplication.pdfCursorTools.switchTool(
                    _pdf_cursor_tools.CursorTool.HAND
                );
                break;

            case 82:
                PDFViewerApplication.rotatePages(90);
                break;

            case 115:
                PDFViewerApplication.pdfSidebar.toggle();
                break;
        }

        if (
            turnPage !== 0 &&
            (!turnOnlyIfPageFit || pdfViewer.currentScaleValue === "page-fit")
        ) {
            if (turnPage > 0) {
                pdfViewer.nextPage();
            } else {
                pdfViewer.previousPage();
            }

            handled = true;
        }
    }

    if (cmd === 4) {
        switch (evt.keyCode) {
            case 13:
            case 32:
                if (
                    !isViewerInPresentationMode &&
                    pdfViewer.currentScaleValue !== "page-fit"
                ) {
                    break;
                }

                if (PDFViewerApplication.page > 1) {
                    PDFViewerApplication.page--;
                }

                handled = true;
                break;

            case 82:
                PDFViewerApplication.rotatePages(-90);
                break;
        }
    }

    if (!handled && !isViewerInPresentationMode) {
        if (
            (evt.keyCode >= 33 && evt.keyCode <= 40) ||
            (evt.keyCode === 32 && curElementTagName !== "BUTTON")
        ) {
            ensureViewerFocused = true;
        }
    }

    if (ensureViewerFocused && !pdfViewer.containsElement(curElement)) {
        pdfViewer.focus();
    }

    if (handled) {
        evt.preventDefault();
    }
}

function beforeUnload(evt) {
    evt.preventDefault();
    evt.returnValue = "";
    return false;
}

const PDFPrintServiceFactory = {
    instance: {
        supportsPrinting: false,

        createPrintService() {
            throw new Error("Not implemented: createPrintService");
        },
    },
};
exports.PDFPrintServiceFactory = PDFPrintServiceFactory;
