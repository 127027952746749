<template>
    <div class="serp-root">
        <div class="serp-outer-wrapper">
            <Transition name="fade" mode="out-in">
                <div v-if="manualsList && !showDetail" class="serp-top">
                    <div
                        class="item-list-outer-wrapper"
                        :class="{
                            minimumExpandItemList:
                                !expandItemList && showDivider,
                            expandItemList: expandItemList,
                            expandedItemListDesktop:
                                mediaQuery.isDesktop || mediaQuery.isLaptop,
                            expandedItemListTablet: mediaQuery.isTablet,
                            expandedItemListMobile: mediaQuery.isMobile,
                        }"
                    >
                        <item-list
                            :manuals="manualsList"
                            @manualClicked="onManualClick"
                        />
                    </div>
                    <div
                        class="item-list-expand-control-wrapper"
                        :class="{ expanded: expandItemList || !showDivider }"
                    >
                        <Divider
                            v-if="showDivider"
                            class="item-list-expand-control-divider"
                            align="center"
                        >
                            <div
                                class="item-list-expand-control-button-wrapper"
                            >
                                <div
                                    class="item-list-expand-control-button"
                                    @click="toggleItemList()"
                                >
                                    <span v-if="expandItemList">
                                        Show Less <i class="pi pi-angle-up"></i>
                                    </span>
                                    <span v-else>
                                        Show More
                                        <i class="pi pi-angle-down"></i>
                                    </span>
                                </div>
                            </div>
                        </Divider>
                    </div>

                    <!-- end Manuals Tile List -->
                </div>
                <div v-else-if="manualsList && !isPortable" class="serp-top">
                    <Detail
                        :manuals="manualsList"
                        :currentManual="currentManual"
                        @closeClick="onCloseDetailClick"
                    />
                </div>
            </Transition>
            <div class="serp-bottom">
                <div v-if="manualsList" class="hideTopBorder"></div>
                <component
                    :is="xmlComponent"
                    v-if="$features('xml')"
                    :currentQuery="enteredQuery"
                    :defaultSearchAction="defaultSearchAction || null"
                ></component>
                <iframe
                    v-else
                    id="yhs-iframe"
                    ref="yhsIframe"
                    :src="frameSource"
                    @load="onIframeLoad"
                    class="web-frame"
                ></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Divider from "primevue/divider";
import ItemList from "../../../../components/itemList/ItemList.vue";
import Detail from "../detail/Detail.vue";
import { defineAsyncComponent } from "vue";
import { YAHOO_YHS_FRAME_URL } from "@/utility.js";

export default {
    name: "Serp",
    props: ["currentQuery", "manuals", "showDetailView", "query"],
    components: {
        ItemList,
        Divider,
        Detail,
    },
    data() {
        const qs = new URLSearchParams(window.location.search);
        return {
            perPageNo: 60,
            framePage: qs.get("yp") || 0,
            enteredQuery: this.currentQuery || null,
            manualsList: this.manuals || null,
            manualsNumPages: null,
            manualsTotalNum: null,
            manualsCurrentPage: null,
            expandItemList: false,
            typeTag: qs.get("type") || null,
            start:
                qs.start && !isNaN(qs.start) && qs.start > 0 ? qs.start : null,
            frameKey: 1,
            currentManual: null,
            showDetail: this.showDetailView,
            defaultSearchAction: null,
            frameSource: undefined,
            frameQuery: undefined,
        };
    },
    watch: {
        manuals(nv) {
            this.manualsList = nv;
        },
        showDetailView(nv) {
            this.showDetail = nv;
        },
        currentQuery(nv) {
            this.enteredQuery = nv;
            this.start = null;
            his.frameKey = this.frameKey + 1;
            this.start = null;
            if (this.currentQuery !== this.frameQuery)
                this.frameSource = this.getFrameSource();
        },
        isPortable(nv) {
            if (nv) this.showDetail = false;
        },
    },
    created() {
        this.frameSource = this.getFrameSource();
    },
    computed: {
        xmlComponent() {
            return defineAsyncComponent(() => import(`./types/Web_XML.vue`));
        },
        isManualsQuery() {
            if (this.enteredQuery) {
                // Logic to determine if to search for manuals here
                return this.enteredQuery.toLowerCase().includes("manual");
            }
            return false;
        },
        showDivider() {
            if (this.mediaQuery.isMobile) return this.manualsList.length > 2;
            if (this.mediaQuery.isTablet) return this.manualsList.length > 4;
            if (this.mediaQuery.isLaptop) return this.manualsList.length > 6;
            return this.manualsList.length > 8;
        },
        isPortable() {
            return this.mediaQuery.isPortable;
        },
    },
    mounted() {
        if (this?.$route?.params?.defaultSearchAction != undefined) {
            this.defaultSearchAction = this.$route.params.defaultSearchAction;
        } else this.defaultSearchAction = null;

        if (this.query) document.title = `Searchables - ${this.query}`;
        if (!this.$features("xml")) {
            this.frameKey++;
            this.$nextTick(() => {
                if (window && window.YHS && window.YHS.init) {
                    window.YHS.init({
                        yahoo_domain: "https://search.yahoo.com",
                        iframe_id: "yhs-iframe",
                        debug: false,
                    });
                    window.YHS.events.on(
                        "heightChanged",
                        "yhsHeightChangeListener",
                        (evt) => {
                            if (
                                this.$refs &&
                                this.$refs.yhsIframe &&
                                evt.height
                            ) {
                                this.$refs.yhsIframe.style.height = `${evt.height}px`;
                            }
                        }
                    );

                    window.YHS.events.on(
                        "queryChanged",
                        "yhsQueryChangedListener",
                        (evt) => {
                            const url = new URL(window.location.href);
                            if (evt.query) {
                                this.frameQuery = evt.query;
                                this.$emit(
                                    "queryChange",
                                    decodeURIComponent(evt.query)
                                );
                            }
                            url.searchParams.set(
                                "q",
                                decodeURIComponent(evt.query)
                            );

                            url.searchParams.delete("yp");
                            url.searchParams.delete("ms");

                            url.searchParams.delete("start");
                            const title = `Searchables - ${evt.query}`;
                            document.title = title;

                            window.history.pushState(
                                { path: url.toString() },
                                title,
                                url.toString()
                            );
                        }
                    );

                    window.YHS.events.on(
                        "pageNavigated",
                        "yhsPageNavigatedListener",
                        (evt) => {
                            if (evt && evt.page_url) {
                                const frameUrl = new URL(evt.page_url);
                                const resultStartNumber =
                                    frameUrl.searchParams.get("b");
                                const url = new URL(window.location.href);
                                if (resultStartNumber) {
                                    url.searchParams.set(
                                        "yp",
                                        resultStartNumber
                                    );
                                    this.start = resultStartNumber;
                                    const title = `Searchables - ${this.query}`;
                                    document.title = title;
                                    window.history.pushState(
                                        { path: url.toString() },
                                        title,
                                        url.toString()
                                    );
                                }
                            }
                        }
                    );
                }
            });
        }
    },
    methods: {
        onCloseDetailClick() {
            this.$emit("closeDetailClick");
        },
        onManualClick(manual) {
            if (!this.isPortable) {
                // Not mobile/tablet, open details
                // this.showDetail = true
                this.$emit("manualClicked", manual);
                this.currentManual = manual;
            } else if (manual && (manual.download_pdf_url || manual.url)) {
                // mobile/tablet, open directly at source
                let { url } = manual;
                if (manual.download_pdf_url) url = manual.download_pdf_url;
                window.open(url, "_blank");
            }
        },
        toggleItemList(forceState = false) {
            if (forceState) this.expandItemList = forceState;
            else this.expandItemList = !this.expandItemList;
        },
        getFrameSource() {
            const url = new URL(YAHOO_YHS_FRAME_URL);
            url.searchParams.set("p", this.query);
            url.searchParams.set("type", this.typeTag);
            if (this.framePage) url.searchParams.set("b", this.framePage);

            return url.toString();
        },
        onIframeLoad() {
            this.goToTop();
        },
        goToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style src="./serp.css"></style>
