export default {
    install: (Vue, options) => {
        const params = new URLSearchParams(window.location.search);
        let features = null;
        if (params.get("features")) {
            window.sessionStorage.setItem("features", params.get("features"));
            features = new Set(params.get("features").split(","));
        } else if (window.sessionStorage.getItem("features")) {
            features = new Set(
                window.sessionStorage.getItem("features").split(",")
            );
        }
        Vue.config.globalProperties.$features = (name) =>
            features && features.has(name);
    },
};
