<template>
    <div id="firstrun-root">
        <div id="searchables_widget_1234">
            <div id="searchables_widget_wrapper_1234">
                <div id="searchables_label_1234">
                    <img
                        src="/img/uparrow_icon.svg"
                        class="searchables_bounce"
                    />
                    <span
                        >Try searching for product manuals by typing
                        <strong>'manuals'</strong> after your search term. e.g.
                        "Toyota corolla se manual"</span
                    >

                    <div class="full-width">
                        <img
                            id="product-logo"
                            src="/img/searchformanuals-logo.svg"
                        />
                    </div>
                </div>

                <div id="searchables_branding_1234">
                    <span>Powered by</span> <img src="/img/logo.svg" />
                </div>
            </div>
        </div>

        <div id="searchables-content">
            <div id="searchables-content-wrapper">
                <h1>Find Product Manuals With Searchables</h1>
                <img src="/img/firstrun_manual.png" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "welcome",
};
</script>

<style src="./welcome.css"></style>
