<template>
    <div id="root">
        <div id="main-wrapper">
            <div id="main-header">
                <SearchablesHeader
                    :mobile="isPortable"
                    :currentQuery="query"
                    @inputSubmit="onInputSubmit"
                />
            </div>
            <div id="main-body">
                <div class="main-item-bar-outer-wrapper">
                    <div v-if="manuals" id="manual-results-header">
                        <strong>Product Manual Results</strong> Click to View
                    </div>
                    <ItemFilters
                        v-if="availableFilters"
                        :filterData="availableFilters"
                        :currentlySelected="currentlySelectedFilters"
                        :key="filterKey"
                        @filterChanged="onFilterChange"
                    />
                </div>
                <Transition name="fade" mode="out-in">
                    <Serp
                        v-if="enteredQuery"
                        :manuals="manuals"
                        :currentQuery="enteredQuery"
                        :showDetailView="showDetail"
                        ref="frame"
                        :query="query"
                        @queryChange="onQueryChange"
                        @manualClicked="onManualClick"
                        @closeDetailClick="onCloseDetailClick"
                    />
                </Transition>
            </div>
            <div id="main-footer">
                <SearchablesFooter :mobile="isPortable" />
            </div>
        </div>
    </div>
</template>

<script>
import SearchablesHeader from "@/components/header/SearchablesHeader.vue";
import SearchablesFooter from "@/components/footer/SearchablesFooter.vue";
import ItemFilters from "@/components/itemFilters/ItemFilters.vue";
import Serp from "./views/serp/Serp.vue";
import Detail from "./views/detail/Detail.vue";
import trim from "lodash.trim";
import { pageview } from "../../analytics.js";

export default {
    name: "App",
    components: {
        SearchablesHeader,
        SearchablesFooter,
        ItemFilters,
        Serp,
        Detail,
    },
    data() {
        const qs = new URLSearchParams(window.location.search);
        return {
            showDetail: false,
            query: qs.get("q") || null,
            enteredQuery: qs.q || null,
            manualSearchRes: null,
            bookmarks: null,
            searchData: null,
            currentManual: null,
            perPageNo: 60,
            currentlySelectedFilters: {},
            filterKey: 0,
            manualsQP: qs.get("ms") === "true",
        };
    },
    mounted() {
        pageview();

        const runManualSearch = false;
        const qs = new URLSearchParams(window.location.search);

        this.runCleanSearch(this.query);
    },
    computed: {
        isPortable() {
            return this.mediaQuery.isPortable;
        },
        isManualsQuery() {
            if (this.enteredQuery) {
                // Logic to determine if to search for manuals here
                return (
                    this.enteredQuery.toLowerCase().includes("manual") ||
                    this.manualsQP
                );
            }
            return false;
        },
        manuals() {
            if (
                this.manualSearchRes &&
                this.manualSearchRes?.main?.records != undefined
            ) {
                return this.manualSearchRes.main.records;
            }
            return null;
        },
        availableFilters() {
            if (this.manualSearchRes && this.manualSearchRes.aggregations) {
                const agg = this.manualSearchRes.aggregations;
                const filters = [];
                if (agg?.brand?.buckets?.length != undefined)
                    filters.push({
                        name: "Brands",
                        var: "brand",
                        data: agg.brand.buckets,
                    });
                if (agg?.category?.buckets?.length != undefined)
                    filters.push({
                        name: "Categories",
                        var: "category",
                        data: agg.category.buckets,
                    });
                if (agg?.sub_category?.buckets?.length != undefined)
                    filters.push({
                        name: "Sub Categories",
                        var: "sub_category",
                        data: agg.sub_category.buckets,
                    });
                if (agg?.product?.buckets?.length != undefined)
                    filters.push({
                        name: "Products",
                        var: "product",
                        data: agg.product.buckets,
                    });
                if (agg?.year?.buckets?.length != undefined)
                    filters.push({
                        name: "Years",
                        var: "year",
                        data: agg.year.buckets,
                    });

                if (filters.length > 0) return filters;
            }
            return null;
        },
    },
    methods: {
        onManualClick(manual) {
            this.currentManual = manual;
            this.showDetail = true;
        },
        onCloseDetailClick() {
            this.showDetail = false;
        },
        onQueryChange(query) {
            this.query = query;
        },
        onInputSubmit({ query, isManaulsSearch = false }) {
            const currentQuery = this.enteredQuery;
            if (
                (query && query.trim() != "" && query != this.enteredQuery) ||
                isManaulsSearch
            ) {
                this.changeQueryParams({ q: query, ms: isManaulsSearch });
            } else {
                if (query && query.trim() == "") {
                    this.changeQueryParams({ q: query }, null, "q");
                }
            }
            if (query !== currentQuery || isManaulsSearch)
                this.runCleanSearch(query, isManaulsSearch);
        },
        onFilterChange(newFilters) {
            this.currentlySelectedFilters = newFilters;
            this.runFilteredSearch();
        },
        async runFilteredSearch() {
            const keys = Object.keys(this.currentlySelectedFilters);
            let filters = {};
            if (keys) {
                for (let i in keys) {
                    const key = keys[i];
                    if (
                        Array.isArray(this.currentlySelectedFilters[key]) &&
                        this.currentlySelectedFilters[key].length > 0
                    ) {
                        filters[key] = this.currentlySelectedFilters[key].map(
                            (el) => el.key
                        );
                    }
                }
            }

            if (this.enteredQuery) {
                const manuals = await this.manualsSearch(
                    this.enteredQuery,
                    Object.keys(filters).length > 0 ? filters : null
                );

                if (manuals?.main?.records != undefined) {
                    this.manualSearchRes = manuals;
                    //this.filterKey++
                } else {
                    this.manualsSearchRes = null;
                }
            }
            this.showDetail = false;
        },
        async runCleanSearch(query, alwaysRun) {
            if (!query) return;
            this.enteredQuery = query;
            if (this.isManualsQuery || alwaysRun) {
                this.manualSearchRes = null;
                const manuals = await this.manualsSearch(query);
                if (manuals?.main?.records != undefined) {
                    this.manualSearchRes = manuals;
                } else this.manualsSearchRes = null;
            } else this.manualSearchRes = null;
        },
        async manualsSearch(query, filters = null) {
            this.query = query;
            let facets = {};
            const facetList = [
                "year",
                "brand",
                "category",
                "sub_category",
                "product",
            ];

            for (let i in facetList)
                facets[facetList[i]] = {
                    field: facetList[i],
                    size: 100,
                };

            const manualsQuery = query.replace(/manual+s?/gi, "").trim();

            const body = {
                group: 12,
                q: manualsQuery,
                perPage: this.perPageNo,
                facets: facets,
            };

            if (filters) body.filters = filters;

            const req = await fetch("/core/v2/search", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (req && req.ok) {
                const res = await req.json();
                if (res && res) return res;
            }
            return null;
        },
        changeQueryParams(params, replace = false, removeParams) {
            const url = new URL(window.location);
            if (params && params.q) {
                url.searchParams.set("q", params.q);
                this.query = params.q;
                this.enteredQuery = params.q;
            }

            url.searchParams.delete("yp");
            url.searchParams.delete("ms");
            url.searchParams.delete("start");

            if (params && params.ms) url.searchParams.set("ms", params.ms);

            const title = `Searchables - ${this.query}`;
            document.title = title;
            // Note - catch is purely to prevent incorrect error reporting due to bug in vue router when reloading static assets on the same page
            if (replace)
                window.history.replaceState(
                    { path: url.toString() },
                    title,
                    url.toString()
                );
            else
                window.history.pushState(
                    { path: url.toString() },
                    title,
                    url.toString()
                );
        },
    },
};
</script>

<style src="./main.css"></style>
